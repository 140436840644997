import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Datapoint } from 'typescript-types'

export type ClusterViewState = {
  state?: {
    showAbsoluteValues: boolean
    datapoints: Datapoint[]
    displayName: string
    sectionName: string
  }
  open: boolean
}

const initialState: ClusterViewState = {
  open: false,
}

const clusterViewSlice = createSlice({
  name: 'clusterView',
  initialState,
  reducers: {
    reset: () => initialState,
    setClusterKeys: (state, action: PayloadAction<ClusterViewState>) => {
      return action.payload
    },
  },
})

export const { setClusterKeys, reset } = clusterViewSlice.actions

export default clusterViewSlice.reducer
