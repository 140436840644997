import * as React from 'react'
import { TableCell, TableRow, Typography } from '@mui/material'

type TableHeaderProps = {
  title: string
  numberOfColumns: number
}

const tableRowStle = { minHeight: '48px', backgroundColor: '#8080800f' }
const tableCellStyle = { padding: 1, paddingTop: 2, paddingBottom: 2 }
const typographyStyle = {
  textTransform: 'uppercase',
  fontSize: '0.6rem',
  fontWeight: 700,
}

const TableSectionHeader: React.FC<TableHeaderProps> = ({
  title,
  numberOfColumns,
}) => {
  return (
    <TableRow sx={tableRowStle}>
      <TableCell sx={tableCellStyle}>
        <Typography color='secondary' sx={typographyStyle}>
          {title}
        </Typography>
      </TableCell>
      {Array.from(Array(numberOfColumns)).map((_, idx) => (
        <TableCell key={idx} />
      ))}
    </TableRow>
  )
}

export default React.memo(TableSectionHeader)
