import * as React from 'react'
import { Typography, Box } from '@mui/material'
import { green, red } from '@mui/material/colors'

export enum AttentionType {
  More = 'More',
  Less = 'Less',
  Similar = 'Similar',
}

const ColorBackground: React.FC<{
  variant: AttentionType
  value: string
  differenceText?: string
}> = ({ value, variant, differenceText }) => {
  const backgroundColor = React.useMemo(() => {
    if (variant === AttentionType.More) {
      return green['400']
    }
    if (variant === AttentionType.Less) {
      return red['400']
    }

    return 'white'
  }, [variant])

  const textColor = React.useMemo(() => {
    if (variant === AttentionType.Similar) {
      return 'black'
    }
    return 'white'
  }, [variant])

  const fontWeight = React.useMemo(() => {
    if (variant === AttentionType.Similar) {
      return undefined
    }
    return 'bold'
  }, [variant])

  return (
    <Box display='flex' alignItems='center'>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ width: 25, height: 25, borderRadius: 10, backgroundColor }}
      >
        <Typography fontWeight={fontWeight} color={textColor} variant='body2'>
          {value || ''}
        </Typography>
      </Box>

      {differenceText && (
        <Box padding={1}>
          <Typography fontWeight='bold' variant='body2'>
            {differenceText}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default React.memo(ColorBackground)
