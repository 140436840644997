import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import CommonButton from 'UI/CommonButton'
import { deletePortfolio } from 'reducers/assetView'
import { Portfolio } from 'typescript-types'

const dialogContentStyle = { minWidth: 300, maxWidth: 300, padddingTop: 8 }
const dialogTitleStyle = { fontWeight: 'bold' }

type DeletePortfolioDialogProps = {
  onClose: () => void
  open: boolean
  portfolio?: Portfolio
}

const DeletePortfolioDialog: React.FC<DeletePortfolioDialogProps> = ({
  onClose,
  open,
  portfolio,
}) => {
  const dispatch = useDispatch()
  const commitDeletePortfolio = React.useCallback(() => {
    if (portfolio?.id) {
      dispatch(deletePortfolio(portfolio?.id))
      onClose()
    }
  }, [dispatch, portfolio?.id, onClose])

  return (
    <Dialog maxWidth='sm' open={open} onClose={onClose}>
      <DialogTitle sx={dialogTitleStyle}>Delete portfolio</DialogTitle>
      <DialogContent sx={dialogContentStyle}>
        <Typography>
          Delete portfolio <i>{portfolio?.propertyCompany}</i> from the
          overview.
        </Typography>
      </DialogContent>
      <DialogActions>
        <CommonButton variant='contained' onClick={onClose}>
          Cancel
        </CommonButton>
        <CommonButton
          onClick={commitDeletePortfolio}
          variant='contained'
          color='secondary'
        >
          Delete
        </CommonButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeletePortfolioDialog
