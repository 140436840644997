import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type FilterState = {
  sections: Record<string, boolean>
  filters: Record<string, boolean>
}

const initialState: FilterState = {
  sections: {},
  filters: {},
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    reset: () => initialState,
    resetFilters: (_state, action: PayloadAction<FilterState>) =>
      action.payload,
  },
})

export const { resetFilters, reset } = filterSlice.actions

export default filterSlice.reducer
