import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { DatapointValue } from 'typescript-types'
import { mergeDeepRight } from 'ramda'

export type DatapointsState = Record<
  string,
  Record<string, DatapointValue | null>
>

const initialState: DatapointsState = {}

const datapointsSlice = createSlice({
  name: 'datapointsData',
  initialState,
  reducers: {
    reset: () => initialState,
    addData: (
      state,
      action: PayloadAction<
        Record<string, Record<DatapointValue['id'], DatapointValue | null>>
      >
    ) => mergeDeepRight(state, action.payload),
  },
})

export const { addData, reset } = datapointsSlice.actions

export default datapointsSlice.reducer
