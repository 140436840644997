import * as React from 'react'
import { Dialog, DialogContent, TableContainer, Table } from '@mui/material'
import type { Column } from 'hooks/useOrderedColumns'
import TableHeader from 'UI/TableHeader'
import TableSectionHeader from 'UI/TableSectionHeader'
import DataRow from '../DataRow'
import useHeaderTitles from 'hooks/useHeaderTitles'
import { Datapoint } from 'typescript-types'
import { sortBy, prop } from 'ramda'

const dialogContentStyle = { minWidth: 700, maxWidth: 300, padddingTop: 8 }
const tableContainerStyle = {
  minWidth: 700,
  overflow: 'scroll',
  maxHeight: 800,
  maxWidth: 1000,
  msOverflowStyle: 'none !important',
  scrollbarWidth: 'none !important',
  '-webkit-scrollbar': {
    display: 'none',
  },
}

type ClusterDialogProps = {
  open: boolean
  onClose: () => void
  datapoints?: Datapoint[]
  sectionName?: string
  displayName?: string
  columns: Column[]
  showAbsoluteValues?: boolean
}

const ClusterDialog: React.FC<ClusterDialogProps> = ({
  columns,
  displayName,
  open,
  onClose,
  datapoints,
  sectionName,
  showAbsoluteValues,
}) => {
  const headerTitles = useHeaderTitles(columns, {})
  const sortedDatapoints = React.useMemo(() => {
    const sorted = sortBy(prop('displayName'), datapoints || [])
    return sorted
  }, [datapoints])

  return (
    <Dialog maxWidth='md' open={open} onClose={onClose}>
      <DialogContent sx={dialogContentStyle}>
        <TableContainer sx={tableContainerStyle}>
          <Table stickyHeader>
            <TableHeader columnTitles={headerTitles} />
            <TableSectionHeader
              title={displayName as string}
              numberOfColumns={columns.length}
            />
            {sortedDatapoints.map(
              ({ id, displayName: datapointDisplayName }) => (
                <DataRow
                  key={id}
                  showAbsoluteValues={showAbsoluteValues}
                  displayName={datapointDisplayName}
                  sectionName={sectionName as string}
                  filterName={id}
                  columns={columns}
                />
              )
            )}
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(ClusterDialog)
