import * as React from 'react'
import { useGoogleMapsService } from '../../contexts/GoogleMapsService'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import type { TPlace } from 'types'

const renderOption = (props: any, option: any) => {
  const matches = option.structured_formatting.main_text_matched_substrings
  const parts = parse(
    option.structured_formatting.main_text,
    matches.map((match: any) => [match.offset, match.offset + match.length])
  )

  return (
    <li {...props}>
      <Grid container alignItems='center'>
        <Grid item>
          <Box
            component={LocationOnIcon}
            sx={{ color: 'text.secondary', mr: 2 }}
          />
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                fontWeight: part.highlight ? 700 : 400,
              }}
            >
              {part.text}
            </span>
          ))}
          <Typography variant='body2' color='text.secondary'>
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </li>
  )
}

const renderInput = (params: TextFieldProps) => (
  <TextField {...params} label='Address' fullWidth />
)

const filterOptions = (x: any) => x
const getOptionLabel = (option: any) =>
  typeof option === 'string' ? option : option.description

type AddressSearchProps = {
  value?: TPlace
  setValue: React.Dispatch<React.SetStateAction<TPlace | undefined>>
}

const AddressSearch: React.FC<AddressSearchProps> = ({ value, setValue }) => {
  const { getPlacePrediction } = useGoogleMapsService()
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<readonly TPlace[]>([])

  React.useEffect(() => {
    if (getPlacePrediction) {
      const resultPromise = getPlacePrediction(inputValue)
      if (resultPromise) {
        resultPromise.then((places) => {
          if (places) {
            setOptions(places)
          }
        })
      }
    }
  }, [inputValue, getPlacePrediction])

  const onInputChange = React.useCallback(
    (event, newInputValue) => {
      setInputValue(newInputValue)
    },
    [setInputValue]
  )

  const onChange = React.useCallback(
    (event: any, newValue: TPlace | null) => {
      if (newValue) {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
      }
    },
    [setOptions, setValue, options]
  )

  return (
    <Autocomplete
      id='google-maps'
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  )
}

export default AddressSearch
