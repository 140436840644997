import * as React from 'react'
import { useLocation } from 'react-router-dom'
import useSyncSearchParams from '../useSyncSearchParams'

const useSyncSearchParamsOnPathChange = () => {
  const location = useLocation()
  const syncSearchParamsWithState = useSyncSearchParams()

  React.useEffect(() => {
    syncSearchParamsWithState()
  }, [location.pathname, syncSearchParamsWithState])
}

export default useSyncSearchParamsOnPathChange
