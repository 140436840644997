import * as React from 'react'
import { useDispatch } from 'react-redux'
import TableRow from '@mui/material/TableRow'
import TextCell from 'UI/TextCell'
import { setClusterKeys } from 'reducers/clusterView'
import DataRowItem from '../DataRowItem'
import { Datapoint } from 'typescript-types'
import type { Column } from 'hooks/useOrderedColumns'

const getDivideValueBy = (displayName: string): number => {
  if (displayName.includes('(Mi)')) {
    return 1_000_000
  }
  if (displayName.includes('(Th)')) {
    return 1_000
  }

  return 1
}

const DataRow: React.FC<{
  displayName: string
  filterName: string
  sectionName: string
  columns: Column[]
  children?: Datapoint[]
  expandable?: boolean
  showAbsoluteValues?: boolean
}> = ({
  expandable,
  displayName,
  columns,
  sectionName,
  filterName,
  showAbsoluteValues,
  children,
}) => {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = React.useState(false)
  const divideValueBy = getDivideValueBy(displayName)

  const openCluster = React.useMemo(() => {
    if ((children?.length || 0) > 0) {
      const clusterDatapoints = showAbsoluteValues
        ? children?.filter(({ isBucket }) => !isBucket)
        : children?.filter(({ isBucket }) => isBucket)

      return () => {
        dispatch(
          setClusterKeys({
            state: {
              showAbsoluteValues: Boolean(showAbsoluteValues),
              datapoints: clusterDatapoints || [],
              displayName,
              sectionName,
            },
            open: true,
          })
        )
      }
    }
    return undefined
  }, [children, dispatch, displayName, sectionName, showAbsoluteValues])

  return (
    <>
      <TableRow>
        <TextCell
          onClick={openCluster}
          text={`${displayName}${Boolean(openCluster) ? '*' : ''}`}
        />
        {columns.map(({ data, variant }, idx) => (
          <DataRowItem
            divideValueBy={divideValueBy}
            showAbsoluteValues={showAbsoluteValues}
            expandable={Boolean(expandable)}
            key={idx}
            data={data}
            variant={variant}
            sectionName={sectionName}
            filterName={filterName}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ))}
      </TableRow>
    </>
  )
}

export default React.memo(DataRow)
