import { TableRow } from '@mui/material'
import SmallHeaderCell from 'UI/SmallHeaderCell'
import * as React from 'react'
import type { DataTableProps } from '..'
import { TableCell } from '@mui/material'
import { ColumnVariant } from 'hooks/useOrderedColumns'
import TextCell from 'UI/TextCell'

const NumberOfAssetsRow: React.FC<Pick<DataTableProps, 'columns'>> = ({
  columns,
}) => {
  return (
    <TableRow>
      <SmallHeaderCell text='Number of assets' />
      {columns.map(({ data, variant }, idx) => {
        switch (variant) {
          case ColumnVariant.Focus:
            return <TableCell key={idx} />
          case ColumnVariant.Address:
            return <TableCell key={idx} />
          case ColumnVariant.Portfolio:
            return <TextCell key={idx} text={data.numberOfAssets} />
          case ColumnVariant.Subportfolio:
            return <TextCell key={idx} text={data.numberOfAssets} />
        }
      })}
    </TableRow>
  )
}

export default React.memo(NumberOfAssetsRow)
