import { ColumnVariant } from 'hooks/useOrderedColumns'
import DataCell from '../DataCell'
import PortfolioDataCell from '../PortfolioCell'
import SubportfolioDataCell from '../SubportfolioCell'
import * as React from 'react'
import { Subportfolio } from 'typescript-types'

type DataRowItemProps = {
  variant: ColumnVariant
  data: any
  sectionName: string
  filterName: string
  expanded: boolean
  expandable: boolean
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  showAbsoluteValues?: boolean
  divideValueBy: number
}

const DataRowItem: React.FC<DataRowItemProps> = ({
  variant,
  data,
  sectionName,
  filterName,
  setExpanded,
  expanded,
  expandable,
  showAbsoluteValues,
  divideValueBy,
}) => {
  const selectPath = React.useMemo(
    () => ['datapoints', `${data.lat};${data.long}`, filterName],
    [filterName, data.lat, data.long]
  )

  switch (variant) {
    case ColumnVariant.Focus:
      return (
        <DataCell
          selectPath={selectPath}
          sectionName={sectionName}
          filterName={filterName}
        />
      )
    case ColumnVariant.Address:
      return (
        <DataCell
          selectPath={selectPath}
          sectionName={sectionName}
          filterName={filterName}
          showAbsoluteValues={showAbsoluteValues}
        />
      )
    case ColumnVariant.Portfolio:
      return (
        <PortfolioDataCell
          divideValueBy={divideValueBy}
          id={data.id}
          sectionName={sectionName}
          filterName={filterName}
          expandable={expandable}
          expanded={expanded}
          setExpanded={setExpanded}
          showAbsoluteValues={showAbsoluteValues}
        />
      )
    case ColumnVariant.Subportfolio:
      return (
        <SubportfolioDataCell
          divideValueBy={divideValueBy}
          id={data.id}
          subportfolio={data as Subportfolio}
          sectionName={sectionName}
          filterName={filterName}
          expandable={expandable}
          expanded={expanded}
          setExpanded={setExpanded}
          showAbsoluteValues={showAbsoluteValues}
        />
      )
  }
}

export default DataRowItem
