import { Box, Button } from '@mui/material'
import * as React from 'react'
import type { ButtonProps } from '@mui/material'
import type { FC } from 'react'

const CommonButton: FC<ButtonProps> = React.forwardRef((props, ref) => (
  <Box padding={1}>
    <Button
      ref={ref}
      {...props}
      sx={{ textTransform: 'capitalize', fontWeight: 700 }}
    />
  </Box>
))

export default CommonButton
