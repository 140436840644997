import { API_BASE_URL } from 'utils/network'
import { fetchJson } from 'utils/network'

export const setToken = (token: string): void => {
  localStorage.setItem('flash_due_dilligence_tk', token)
}

export const getToken = (): string =>
  localStorage.getItem('flash_due_dilligence_tk') || ''

export const login = async (email: string, password: string) => {
  try {
    const ops = {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        email,
        password,
      }),
    }
    const json = await fetchJson(`${API_BASE_URL}/users/auth`, ops)
    setToken(json.token as string)
    return json.token
  } catch (e) {
    return null
  }
}
