import { useGetFilterConfigQuery } from 'services'
import * as React from 'react'
import type { Subcluster } from 'typescript-types'

const useSubclusterDatapointMap = () => {
  const { data: config } = useGetFilterConfigQuery()

  const subclusterDatapointMap = React.useMemo(() => {
    const result: Record<
      string,
      Subcluster & { subclusterDatapointId: string }
    > = {}
    config?.forEach((cluster) => {
      cluster.subclusters.forEach((subcluster) => {
        result[subcluster.id] = subcluster
      })
    })

    return result
  }, [config])

  return subclusterDatapointMap
}

export default useSubclusterDatapointMap
