import * as React from 'react'
import { TypographyProps, Typography } from '@mui/material'

const SmallHeaderTypography: React.FC<TypographyProps> = ({
  children,
  sx,
  ...rest
}) => (
  <Typography
    color='secondary'
    sx={{
      textTransform: 'uppercase',
      fontSize: '0.6rem',
      fontWeight: 700,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Typography>
)

export default SmallHeaderTypography
