export const TABLE_CELL_WIDTH = 100
export const DRAWER_WIDTH = 300

export const CONFIG_ORDER = [
  'climate',
  'gentrification',
  'micro_demography',
  'new_supply_and_developments',
  'urban_tourism_climate',
  'nuts3',
  'country_scores',
  'regional_climate_risk',
]
