import * as React from 'react'
import { TableCell } from '@mui/material'
import SmallHeaderTypo from '../SmallHeaderTypo'

type TextCellProps = {
  text: string
}

const TextCell: React.FC<TextCellProps> = ({ text }) => (
  <TableCell
    sx={{
      verticalAlign: 'center',
      padding: 1,
      paddingTop: 2,
      paddingBottom: 2,
    }}
  >
    <SmallHeaderTypo>{text}</SmallHeaderTypo>
  </TableCell>
)

export default React.memo(TextCell)
