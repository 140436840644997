import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { deleteSubportfolio } from 'reducers/assetView'
import CommonButton from 'UI/CommonButton'
import { Subportfolio } from 'typescript-types'

const dialogContentStyle = { minWidth: 300, maxWidth: 300, padddingTop: 8 }
const dialogTitleStyle = { fontWeight: 'bold' }

type DeleteSubportfolioDialogProps = {
  onClose: () => void
  open: boolean
  subportfolio?: Subportfolio
}

const DeleteSubportfolioDialog: React.FC<DeleteSubportfolioDialogProps> = ({
  onClose,
  open,
  subportfolio,
}) => {
  const dispatch = useDispatch()
  const commitDeletePortfolio = React.useCallback(() => {
    if (subportfolio?.id) {
      dispatch(deleteSubportfolio(subportfolio?.id))
      onClose()
    }
  }, [dispatch, subportfolio?.id, onClose])

  return (
    <Dialog maxWidth='sm' open={open} onClose={onClose}>
      <DialogTitle sx={dialogTitleStyle}>Delete subportfolio</DialogTitle>
      <DialogContent sx={dialogContentStyle}>
        <Typography>
          Delete subportfolio <i>{subportfolio?.propertyCompany}</i> from the
          overview.
        </Typography>
      </DialogContent>
      <DialogActions>
        <CommonButton variant='contained' onClick={onClose}>
          Cancel
        </CommonButton>
        <CommonButton
          onClick={commitDeletePortfolio}
          variant='contained'
          color='secondary'
        >
          Delete
        </CommonButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteSubportfolioDialog
