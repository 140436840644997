import * as React from 'react'
import type { Column } from 'hooks/useOrderedColumns'
import { ColumnVariant } from 'hooks/useOrderedColumns'
import { Subportfolio, Portfolio } from 'typescript-types'

const useHeaderTitle = (
  columns: Column[],
  {
    openDeleteFocusDialog,
    openDeleteAddressDialog,
    openDeletePortfolioDialog,
    openDeleteSubportfolioDialog,
  }: {
    openDeleteFocusDialog?: () => void
    openDeleteAddressDialog?: (data: {
      id: string
      lat: string
      long: string
      address: string
      assetType: string
    }) => void
    openDeletePortfolioDialog?: (portfolio: Portfolio) => void
    openDeleteSubportfolioDialog?: (subportfolio: Subportfolio) => void
  }
): { title: string; deleteAction?: () => void; subtitles?: string[] }[] =>
  React.useMemo(
    () =>
      columns.map(({ data, variant }) => {
        switch (variant) {
          case ColumnVariant.Address:
            return {
              title: data.address,
              subtitles: [data.assetType],
              deleteAction:
                openDeleteAddressDialog &&
                (() => openDeleteAddressDialog(data)),
            }
          case ColumnVariant.Focus:
            return {
              title: data.address,
              deleteAction: openDeleteFocusDialog,
              subtitles: [data.assetType],
            }
          case ColumnVariant.Portfolio:
            return {
              title: data.propertyCompany,
              subtitles: [data.reitStatus, data.countryOfListing],
              deleteAction:
                openDeletePortfolioDialog &&
                (() => openDeletePortfolioDialog(data)),
            }
          case ColumnVariant.Subportfolio:
            return {
              title: (data as Subportfolio).subportfolioName,
              subtitles: [data.reitStatus, data.sector, data.country],
              deleteAction:
                openDeleteSubportfolioDialog &&
                (() => openDeleteSubportfolioDialog(data)),
            }
        }
      }),
    [
      columns,
      openDeleteFocusDialog,
      openDeleteAddressDialog,
      openDeletePortfolioDialog,
      openDeleteSubportfolioDialog,
    ]
  )

export default useHeaderTitle
