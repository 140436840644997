import * as React from 'react'
import { TableCell, TableHead, TableRow } from '@mui/material'
import TableHeaderCell from 'UI/TableHeaderCell'
import { TABLE_CELL_WIDTH } from '../../constants'

type TableHeaderProps = {
  columnTitles: {
    subtitles?: string[]
    title: string
    deleteAction?: () => void
  }[]
}

const TableHeader: React.FC<TableHeaderProps> = ({ columnTitles }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          width={TABLE_CELL_WIDTH}
          sx={{ minWidth: TABLE_CELL_WIDTH }}
        />
        {columnTitles.map(({ title, deleteAction, subtitles }, idx) => (
          <TableHeaderCell
            onClick={deleteAction}
            key={`${title}-${idx}`}
            title={title}
            subtitles={subtitles}
          />
        ))}
      </TableRow>
    </TableHead>
  )
}

export default React.memo(TableHeader)
