import * as React from 'react'
import { Box, Tabs, Tab } from '@mui/material'
import AddAddressDialog from './AddAddressDialog'
import AssetData from './AssetData'
import AssetDataService from 'contexts/AssetDataService'
import Filter from 'components/Filter'
import DirectionsIcon from '@mui/icons-material/Directions'
import TableRowsIcon from '@mui/icons-material/TableRows'
import useOpenAndClose from 'hooks/useOpenAndClose'
import useFocusAddress from 'hooks/useFocusAddress'
import Main from 'UI/Main'
import { DRAWER_WIDTH } from '../../constants'
import Drawer from 'UI/Drawer'
import Button from '@mui/material/Button'
import useSyncSearchParamsEffect from 'hooks/useSyncSearchParamsOnPathChange'
import Map from './Map'
import { Header } from 'components/Header'

const Home = () => {
  const [tabValue, setTabValue] = React.useState(0)

  const handleTabChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTabValue(newValue)
    },
    [setTabValue]
  )

  const [drawerOpen, openDrawer, closeDrawer] = useOpenAndClose(true)
  useSyncSearchParamsEffect()

  const focusAddress = useFocusAddress()
  const [focusDialogOpen, setFocusDialogOpen] = React.useState(false)
  const openFocusDialog = React.useCallback(() => setFocusDialogOpen(true), [])
  const closeFocusDialog = React.useCallback(
    () => setFocusDialogOpen(false),
    []
  )

  return (
    <AssetDataService>
      <Box height='100%'>
        <Header />
        <Box display='flex'>
          <Drawer
            width={DRAWER_WIDTH}
            isOpen={drawerOpen}
            open={openDrawer}
            close={closeDrawer}
          >
            <Tabs
              indicatorColor='secondary'
              textColor='secondary'
              variant='fullWidth'
              value={tabValue}
              onChange={handleTabChange}
              sx={{
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              <Tab
                icon={<TableRowsIcon fontSize='small' />}
                iconPosition='end'
                color='secondary'
                label='Table'
              />
              <Tab
                icon={<DirectionsIcon fontSize='small' />}
                iconPosition='end'
                color='secondary'
                label='Map'
              />
            </Tabs>
            <Filter />
          </Drawer>
          <Main open={drawerOpen}>
            <Box sx={{ height: 'calc(100% - 64px)' }} marginTop={'64px'}>
              {tabValue === 0 ? (
                !focusAddress ? (
                  <Box
                    flexGrow={1}
                    height='100%'
                    width='100%'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Button
                      onClick={openFocusDialog}
                      variant='outlined'
                      color='secondary'
                    >
                      Add focus address
                    </Button>
                  </Box>
                ) : (
                  <AssetData drawerOpen={drawerOpen} />
                )
              ) : (
                <Map />
              )}
            </Box>
          </Main>
        </Box>
      </Box>
      <AddAddressDialog
        onClose={closeFocusDialog}
        isFocusAddress
        open={focusDialogOpen}
      />
    </AssetDataService>
  )
}

export default React.memo(Home)
