import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import type { SelectProps, InputLabelProps, MenuItemProps } from '@mui/material'
import * as React from 'react'

type DropDownProps = {
  selectProps?: SelectProps
  inputLabelProps?: InputLabelProps
  label: string
  options: (MenuItemProps & { label: string })[]
}

const DropDown: React.FC<DropDownProps> = ({
  selectProps,
  inputLabelProps,
  label,
  options,
}) => (
  <FormControl fullWidth>
    <InputLabel {...inputLabelProps}>{label}</InputLabel>
    <Select {...selectProps}>
      {options.map(({ label: optionLabel, ...menuItemProps }) => (
        <MenuItem key={optionLabel} {...menuItemProps}>
          {optionLabel}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default DropDown
