import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material'
import AlertProvider from 'contexts/AlertProvider'
import { PersistGate } from 'redux-persist/integration/react'
import GoogleMapsService from './contexts/GoogleMapsService'
import Router from './router'
import { store, persistor } from './store'
import theme from './theme'

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <AlertProvider>
            <GoogleMapsService>
              <Router />
            </GoogleMapsService>
          </AlertProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
