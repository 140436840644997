import React, { useCallback, useState, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, batch } from 'react-redux'
import { IconButton, Menu, MenuItem, Typography, Box } from '@mui/material'
import { setToken } from 'utils/auth'
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material'
import { reset as assetViewReset } from 'reducers/assetView'
import { reset as assetDataReset } from 'reducers/assetData'
import { reset as filterReset } from 'reducers/filter'
import { reset as datapointsReset } from 'reducers/datapoints'
import { reset as clusterViewReset } from 'reducers/clusterView'
import { useGetUserQuery } from 'services'
import EditUserDialog from '../EditUserDialog'

const UserMenu = () => {
  const { data: user } = useGetUserQuery()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleLogout = useCallback(() => {
    batch(() => {
      dispatch(assetDataReset())
      dispatch(assetViewReset())
      dispatch(filterReset())
      dispatch(datapointsReset())
      dispatch(clusterViewReset())
    })
    setToken('')
    handleClose()
    navigate('/login')
  }, [handleClose, navigate, dispatch])

  const handleEditUserClick = useCallback(() => {
    setEditUserDialogOpen(true)
    setAnchorEl(null)
  }, [])

  const closeEditUserDialog = useCallback(
    () => setEditUserDialogOpen(false),
    []
  )

  return (
    <>
      <Box display='flex' alignItems='center' flexDirection='row'>
        <Typography
          sx={{ marginRight: 1 }}
          textTransform='capitalize'
          fontSize='0.7rem'
          fontWeight='900'
          color='white'
        >
          {user?.email}
        </Typography>
        <IconButton size='small' onClick={handleClick}>
          <AccountCircleIcon fontSize='large' />
        </IconButton>
      </Box>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEditUserClick}>Edit password</MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
      <EditUserDialog
        open={editUserDialogOpen}
        handleClose={closeEditUserDialog}
      />
    </>
  )
}

export default memo(UserMenu)
