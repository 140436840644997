import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  TextField,
} from '@mui/material'
import CommonButton from 'UI/CommonButton'
import { useToast } from 'contexts/AlertProvider'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete } from '@mui/material'
import { setState } from 'reducers/assetView'
import type { TextFieldProps } from '@mui/material'
import { useDispatch } from 'react-redux'
import {
  useGetAllFilterPresetsQuery,
  useDeleteFilterPresetMutation,
} from 'services'
import useSyncSearchParams from 'hooks/useSyncSearchParams'
import * as React from 'react'
import type { FilterPreset } from 'typescript-types'

type LoadPresetDialogProps = {
  open: boolean
  onClose: () => void
}

const getOptionLabel = (option: FilterPreset) => option.name
const renderInput = (params: TextFieldProps) => (
  <TextField {...params} label='Filter preset' />
)

const LoadPresetDialog: React.FC<LoadPresetDialogProps> = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch()
  const [deleteFilter] = useDeleteFilterPresetMutation()
  const sendToast = useToast()

  const syncSearchParams = useSyncSearchParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filterPreset, setFilterPreset] = React.useState<FilterPreset | null>()
  const { data: filterPresets } = useGetAllFilterPresetsQuery()
  const changePreset = React.useCallback(
    (_, value) => setFilterPreset(value),
    []
  )

  // this place we have to thing about maintaining order in the query params
  const loadPreset = React.useCallback(() => {
    try {
      // delete all the current filters
      searchParams.forEach((_value, key) => {
        if (key.slice(0, 6) === 'filter') {
          searchParams.delete(key)
        }
        if (key === 'section') {
          searchParams.delete(key)
        }
      })

      // set the new filters
      if (filterPreset) {
        filterPreset.preset.filters.forEach((filter) => {
          searchParams.append('filter', filter)
        })

        filterPreset.preset.sections.forEach((section) => {
          searchParams.append('section', section)
        })

        dispatch(
          setState({
            portfolio: filterPreset.preset.portfolios,
            subportfolio: filterPreset.preset.subportfolios,
            address: filterPreset.preset.addresses,
            focusAddress: filterPreset.preset.focusAddress,
          })
        )
      }

      setSearchParams(searchParams)
      syncSearchParams()
      onClose()
    } catch (e) {
      console.error(e)
    }
  }, [
    dispatch,
    searchParams,
    filterPreset,
    setSearchParams,
    syncSearchParams,
    onClose,
  ])

  const onDeleteFilter = React.useCallback(() => {
    if (filterPreset) {
      deleteFilter(filterPreset.id)
        .unwrap()
        .then(() => {
          setFilterPreset(null)
          sendToast({ severity: 'success', message: 'Filter preset deleted' })
        })
    }
  }, [deleteFilter, filterPreset, sendToast])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: 'bold', minWidth: 500 }}>
        Load filter preset
      </DialogTitle>
      <DialogContent>
        <Typography>Load a selection of filters.</Typography>
        <Box paddingTop={2}>
          <Autocomplete
            key='filter-preset'
            getOptionLabel={getOptionLabel}
            renderInput={renderInput}
            options={filterPresets || []}
            onChange={changePreset}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <CommonButton
          color='secondary'
          onClick={onDeleteFilter}
          disabled={!filterPreset}
        >
          delete
        </CommonButton>
        <CommonButton color='secondary' onClick={onClose}>
          cancel
        </CommonButton>
        <CommonButton
          variant='contained'
          disabled={!filterPreset}
          onClick={loadPreset}
        >
          load
        </CommonButton>
      </DialogActions>
    </Dialog>
  )
}

export default LoadPresetDialog
