import * as React from 'react'

type UseOpenAndClose = [boolean, () => void, () => void]

const useOpenAndClose = (initial?: boolean): UseOpenAndClose => {
  const [isOpen, setIsOpen] = React.useState(initial || false)
  const open = React.useCallback(() => setIsOpen(true), [])
  const close = React.useCallback(() => setIsOpen(false), [])

  return [isOpen, open, close]
}

export default useOpenAndClose
