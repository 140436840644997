import { Loader } from '@googlemaps/js-api-loader'
import { throttle } from 'lodash'
import React from 'react'
import type { FC } from 'react'
import type { TGoogleMapsServiceContext } from './types'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import type { TPlace } from 'types'

const GoogleMapsServiceContext = React.createContext(
  {}
) as TGoogleMapsServiceContext

const GoogleMapsService: FC = ({ children }) => {
  const [autocompleteService, setAutocompleteService] = React.useState<any>()
  const [geocoder, setGeocoder] = React.useState<any>()
  const [maps, setMaps] = React.useState<any>()

  const getPlacePrediction = React.useCallback(
    (input: string) =>
      new Promise<TPlace[] | undefined>((resolve, reject) => {
        try {
          if (autocompleteService) {
            autocompleteService.getPlacePredictions(
              { input },
              (results?: TPlace[]) => resolve(results)
            )
          } else {
            throw new Error('Google maps service not loaded')
          }
        } catch (e) {
          reject(e)
        }
      }),
    [autocompleteService]
  )

  const initMap = React.useCallback(
    (mapElement: HTMLElement, center: { lat: number; long: number }) => {
      const map = new maps.Map(mapElement, {
        zoom: 7,
        center,
        mapTypeControl: false,
      })
      map.set('styles', [
        {
          featureType: 'all',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }],
        },
        {
          elementType: 'geometry',
          stylers: [{ color: '#f5f5f5' }],
        },
        {
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [{ color: '#616161' }],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#f5f5f5' }],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#bdbdbd' }],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ color: '#eeeeee' }],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#757575' }],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{ color: '#e5e5e5' }],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#9e9e9e' }],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{ color: '#ffffff' }],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#757575' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{ color: '#dadada' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#616161' }],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#9e9e9e' }],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [{ color: '#e5e5e5' }],
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [{ color: '#eeeeee' }],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{ color: '#c9c9c9' }],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#9e9e9e' }],
        },
      ])
      return map
    },
    [maps]
  )

  const addMarker = React.useCallback(
    (map: any, color: string, position: { lat: number; long: number }) => {
      const marker = new maps.Marker({
        position,
        map,
        icon: {
          path: faLocationDot.icon[4] as string,
          fillColor: color,
          fillOpacity: 1,
          anchor: new maps.Point(
            faLocationDot.icon[0] / 2, // width
            faLocationDot.icon[1] // height
          ),
          strokeWeight: 1,
          strokeColor: '#ffffff',
          scale: 0.045,
        },
      })
      return marker
    },
    [maps]
  )

  const getAddress = React.useCallback(
    (lat: string, long: string) =>
      new Promise((resolve, reject) => {
        try {
          geocoder.geocode(
            { location: { lat: parseFloat(lat), lng: parseFloat(long) } },
            (results: any) => {
              resolve(results)
            }
          )
        } catch (e) {
          reject(e)
        }
      }),
    [geocoder]
  )

  const getPlacePredictionThrottled = React.useMemo(
    () => throttle(getPlacePrediction, 200),
    [getPlacePrediction]
  )

  const getGeocode = React.useCallback(
    (placeId: string) =>
      new Promise((resolve, reject) => {
        try {
          geocoder.geocode({ placeId }, (results: any) => {
            resolve(results)
          })
        } catch (e) {
          reject(e)
        }
      }),
    [geocoder]
  )

  React.useEffect(() => {
    if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
      console.error(
        "Please set 'REACT_APP_GOOGLE_MAPS_API_KEY' in .env.local file"
      )
      return
    }

    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    })
    loader
      .load()
      .then(({ maps: newMaps }) => {
        setMaps(newMaps)
        setAutocompleteService(new newMaps.places.AutocompleteService())
        setGeocoder(new newMaps.Geocoder())
      })
      .catch((err) => console.error('ERRPR', err))
  }, [])

  const value = React.useMemo(
    () => ({
      getPlacePrediction: getPlacePredictionThrottled,
      getGeocode,
      getAddress,
      geocoder,
      initMap,
      addMarker,
    }),
    [
      getPlacePredictionThrottled,
      getGeocode,
      getAddress,
      geocoder,
      initMap,
      addMarker,
    ]
  )

  return (
    <GoogleMapsServiceContext.Provider value={value}>
      {children}
    </GoogleMapsServiceContext.Provider>
  )
}

export const useGoogleMapsService = () =>
  React.useContext(GoogleMapsServiceContext)

export default GoogleMapsService
