import { Navigate, Outlet } from 'react-router-dom'
import { getToken } from '../../utils/auth'

var PrivateRoute = () => {
  return getToken() ? (
    <Outlet />
  ) : (
    <Navigate replace to={{ pathname: '/login' }} />
  )
}

export default PrivateRoute
