import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
  Typography,
  Box,
} from '@mui/material'
import { addPortfolio, addSubportfolio } from 'reducers/assetView'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import type { TextFieldProps } from '@mui/material'
import { useGetAllPortfoliosQuery, useGetSubportfoliosQuery } from 'services'
import type { Portfolio, Subportfolio } from 'typescript-types'
import CommonButton from 'UI/CommonButton'
import * as React from 'react'

const getOptionLabel = (option: Portfolio) => option.propertyCompany
const getOptionLabelSubportfolio = (option: Subportfolio) =>
  option.subportfolioName
const renderInput = (params: TextFieldProps) => (
  <TextField {...params} label='Portfolio' />
)

const renderInputSubPortfolio = (params: TextFieldProps) => (
  <TextField {...params} label='Sub portfolio' />
)

const randomColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`

const isOptionEqualToValue = (optionA: Portfolio, optionB: Portfolio) =>
  Boolean(optionA.id && optionB.id && optionA.id === optionB.id)

const isSubportfolioOptionEqualToValue = (
  optionA: Subportfolio,
  optionB: Subportfolio
) =>
  Boolean(
    optionA.subportfolioName &&
      optionB.subportfolioName &&
      optionA.subportfolioName === optionB.subportfolioName
  )

const dialogContentStyle = { minWidth: 300, maxWidth: 300, padddingTop: 8 }
const dialogTitleStyle = { fontWeight: 'bold' }

const AddPortfolioDialog = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const dispatch = useDispatch()
  const subportfoliosInView = useSelector(
    (state: RootState) => state.assetView.subportfolio
  )
  const { data: allPortfolios } = useGetAllPortfoliosQuery()

  const [selectedPortfolio, setSelectedPortfolio] = React.useState<Portfolio>()
  const [selectedSubportfolio, setSelectedSubportfolio] =
    React.useState<Subportfolio | null>()
  const { data: subportfolios } = useGetSubportfoliosQuery(
    selectedPortfolio?.id || '',
    { skip: !selectedPortfolio }
  )

  const unususedSubportfolios = React.useMemo(
    () =>
      (subportfolios || []).filter(
        ({ subportfolioName }) => !subportfoliosInView[subportfolioName]
      ),
    [subportfolios, subportfoliosInView]
  )

  const setPortfolio = React.useCallback((event, newInputValue) => {
    setSelectedPortfolio(newInputValue)
    setSelectedSubportfolio(undefined)
  }, [])

  const setSubportfolio = React.useCallback((event, newInputValue) => {
    setSelectedSubportfolio(newInputValue)
  }, [])

  const onAddPortfolio = React.useCallback(() => {
    if (selectedPortfolio) {
      dispatch(addPortfolio({ ...selectedPortfolio, color: randomColor() }))
    }
  }, [dispatch, selectedPortfolio])

  const onAddSubPortfolio = React.useCallback(() => {
    if (selectedSubportfolio) {
      dispatch(
        addSubportfolio({ ...selectedSubportfolio, color: randomColor() })
      )
    }
  }, [dispatch, selectedSubportfolio])

  return (
    <Dialog maxWidth='sm' open={open} onClose={onClose}>
      <DialogTitle sx={dialogTitleStyle}>Add portfolio</DialogTitle>
      <DialogContent sx={dialogContentStyle}>
        <Typography>
          Select one of the asset portfolios and add it to the comparison.
        </Typography>
        <Box paddingTop={2} display='flex'>
          <Autocomplete
            fullWidth
            getOptionLabel={getOptionLabel}
            options={allPortfolios || []}
            renderInput={renderInput}
            onChange={setPortfolio}
            isOptionEqualToValue={isOptionEqualToValue}
          />
          <CommonButton
            disabled={!selectedPortfolio}
            variant='contained'
            onClick={onAddPortfolio}
          >
            add
          </CommonButton>
        </Box>
        <Box paddingTop={2} display='flex'>
          <Autocomplete
            key={selectedPortfolio?.id || 'sub-portfolio'}
            fullWidth
            clearOnEscape
            clearOnBlur
            disabled={!subportfolios}
            getOptionLabel={getOptionLabelSubportfolio}
            options={unususedSubportfolios}
            renderInput={renderInputSubPortfolio}
            onChange={setSubportfolio}
            isOptionEqualToValue={isSubportfolioOptionEqualToValue}
          />
          <CommonButton
            disabled={!selectedSubportfolio}
            variant='contained'
            onClick={onAddSubPortfolio}
          >
            add
          </CommonButton>
        </Box>
      </DialogContent>
      <DialogActions>
        <CommonButton variant='contained' onClick={onClose}>
          cancel
        </CommonButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(AddPortfolioDialog)
