import type { FC } from 'react'
import { Skeleton, Typography } from '@mui/material'
import { Box } from '@mui/system'

type LoadingValueProps = {
  value?: null | unknown
}

const LoadingValue: FC<LoadingValueProps> = ({ value, children }) => {
  if (value === undefined) {
    return <Skeleton />
  }
  if (value === null) {
    return (
      <Box>
        <Typography color='gray' fontSize='0.5rem'>
          Missing
        </Typography>
      </Box>
    )
  }
  return <>{children}</>
}

export default LoadingValue
