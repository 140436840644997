import * as React from 'react'
import { Box, TableCell, Typography } from '@mui/material'
import { TABLE_CELL_WIDTH } from '../../constants'

type TableHeaderCellProps = {
  title: string
  subtitles?: string[]
  onClick?: () => void
}

const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  title,
  subtitles,
  onClick,
}) => {
  return (
    <TableCell
      sx={{
        padding: 1,
        maxWidth: TABLE_CELL_WIDTH,
        minWidth: TABLE_CELL_WIDTH,
        verticalAlign: 'top',
        cursor: onClick ? 'pointer' : undefined,
      }}
      width={TABLE_CELL_WIDTH}
      align='left'
      onClick={onClick}
    >
      <Box>
        <Typography
          sx={{ wordBreak: 'break-word', maxWidth: TABLE_CELL_WIDTH }}
          fontWeight='bold'
        >
          {title}
        </Typography>
        {subtitles && (
          <Box paddingTop={0.5}>
            {subtitles.map((subtitle, idx) => (
              <Typography key={`${subtitle}-${idx}`} variant='body2'>
                {subtitle}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    </TableCell>
  )
}

export default React.memo(TableHeaderCell)
