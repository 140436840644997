import { DatapointValue } from 'typescript-types'

export const createErrorState = (keys: string[], newFilters: string[]) => {
  const result: Record<string, Record<DatapointValue['id'], null>> = {}

  for (const key of keys) {
    for (const filter of newFilters) {
      result[key] = {
        ...result[key],
        [filter]: null,
      }
    }
  }

  return result
}
