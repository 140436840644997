import { API_BASE_URL } from 'utils/network'
import { DataDescription } from 'types'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type {
  DatapointValue,
  FilterPreset,
  Portfolio,
  Subportfolio,
  UserWithTenant,
} from 'typescript-types'
import { getToken } from '../utils/auth'
import { Config } from 'typescript-types'

export type AssetLocations = {
  portfolioId: string
  assets: {
    latitude: number
    longitude: number
    subportfolioId: string
  }[]
}[]

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ['FilterPreset'],
  endpoints: (builder) => ({
    getAssetsByPortfolioId: builder.query<AssetLocations, string[]>({
      query: (portfolioIds) =>
        `/data/assets/by-portfolio-ids?${portfolioIds
          .map((id) => `portfolio_id=${id}`)
          .join('&')}&tk=${getToken()}`,
    }),
    updatePassword: builder.mutation<
      void,
      { userId: string; currentPassword: string; newPassword: string }
    >({
      query: ({ currentPassword, newPassword, userId }) => ({
        url: `/users/${userId}/password?tk=${getToken()}`,
        method: 'PATCH',
        body: {
          currentPassword,
          newPassword,
        },
      }),
    }),
    getUser: builder.query<UserWithTenant, void>({
      query: () => `/users/user_by_token?tk=${getToken()}`,
    }),
    login: builder.query<
      { token: string },
      { email: string; password: string }
    >({
      query: (body) => ({
        url: '/users/auth',
        body: body,
        method: 'POST',
      }),
    }),
    getPortfolioAssetLocations: builder.mutation<AssetLocations, string>({
      query: (id) => `/data/portfolios/${id}/assets/location?tk=${getToken()}`,
    }),
    getSubportfolioAssetLocations: builder.mutation<AssetLocations, string>({
      query: (id) =>
        `/data/subportfolios/${id}/assets/location?tk=${getToken()}`,
    }),
    deleteFilterPreset: builder.mutation<void, number>({
      query: (id) => ({
        method: 'DELETE',
        url: `/presets/${id}?tk=${getToken()}`,
      }),
      invalidatesTags: ['FilterPreset'],
    }),
    getAllFilterPresets: builder.query<FilterPreset[], void>({
      query: () => `/presets?tk=${getToken()}`,
      providesTags: ['FilterPreset'],
    }),
    insertFilterPreset: builder.mutation<
      FilterPreset,
      Omit<FilterPreset, 'id'>
    >({
      query: (requestBody) => ({
        url: `/presets?tk=${getToken()}`,
        body: requestBody,
        method: 'POST',
      }),
      invalidatesTags: ['FilterPreset'],
    }),
    getSubportfolios: builder.query<Subportfolio[], string>({
      query: (id) => `/data/portfolios/${id}/subportfolios?tk=${getToken()}`,
    }),
    getPortfolioAssetScoresByFilter: builder.query<
      DatapointValue[],
      { id: string; datapointId: string }
    >({
      query: ({ datapointId, id }) =>
        `data/portfolios/${id}/assets/datapoints/${datapointId}?tk=${getToken()}`,
    }),
    getSubportfolioAssetScoresByFilter: builder.query<
      DatapointValue[],
      { id: string; datapointId: string; sector: string; country: string }
    >({
      query: ({ datapointId, id, sector, country }) =>
        `data/portfolios/${id}/subportfolios/assets/datapoints/${datapointId}?tk=${getToken()}&sector=${sector}&country=${country}`,
    }),
    getSubportfolioAssetScore: builder.query<
      Record<string, number>,
      { portfolioId: string; sector: string; country: string }
    >({
      query: ({ portfolioId, sector, country }) =>
        `/data/portfolios/${portfolioId}/subportfolios/score?country=${country}&sector=${sector}&tk=${getToken()}`,
    }),
    getPortfolioAssetScore: builder.query<Record<string, number>, string>({
      query: (portfolioId) =>
        `/data/portfolios/${portfolioId}/score?tk=${getToken()}`,
    }),
    getAddressAssetScore: builder.query<
      { score: number },
      { latlong: string; sector: string }
    >({
      query: ({ sector, latlong }) =>
        `/data/score/by-latlong?latlong=${latlong}&sector=${sector}&tk=${getToken()}`,
    }),
    getFilterConfig: builder.query<Config, void>({
      query: () => `config?tk=${getToken()}`,
      // @ts-ignore
      transformResponse: (response) => {
        return response as DataDescription
      },
    }),
    getAssetSectors: builder.query<string[], void>({
      query: () => `/data/assets/sectors?tk=${getToken()}`,
    }),
    getAllPortfolios: builder.query<Portfolio[], void>({
      query: () => `data/portfolios?tk=${getToken()}`,
    }),
    getAssetData: builder.query<any, { lat?: string; long?: string }>({
      query: ({ lat, long }) =>
        `data/assets/by-coordinates?lat=${lat}&long=${long}&tk=${getToken()}`,
    }),
    getGeoInfo: builder.query<any, { lat: string; long: string }[]>({
      query: (latlongs) => {
        const queryPart = latlongs
          .map(({ lat, long }) => `latlong=${lat};${long}`)
          .join('&')
        return `/data/resolve-coordinates?${queryPart}&tk=${getToken()}`
      },
      // @ts-ignore
      transformResponse: (response: any) => {
        const levelCodeMap: Record<string, Record<number, string>> = {}
        // @ts-ignore
        response.forEach(({ lat, long, nuts }) => {
          nuts.forEach(({ levelCode, ...rest }: { levelCode: number }) => {
            // @ts-ignore
            levelCodeMap[`${lat};${long}`] = {
              ...levelCodeMap[`${lat};${long}`],
              [levelCode]: rest,
            }
          })
        })

        return levelCodeMap
      },
    }),
  }),
})

export const {
  useGetAssetsByPortfolioIdQuery,
  useUpdatePasswordMutation,
  useGetUserQuery,
  useLazyLoginQuery,
  useDeleteFilterPresetMutation,
  useGetAllFilterPresetsQuery,
  useInsertFilterPresetMutation,
  useGetSubportfolioAssetScoreQuery,
  useGetSubportfoliosQuery,
  useGetSubportfolioAssetScoresByFilterQuery,
  useGetPortfolioAssetScoresByFilterQuery,
  useGetPortfolioAssetScoreQuery,
  useGetFilterConfigQuery,
  useGetAssetDataQuery,
  useGetGeoInfoQuery,
  useGetAllPortfoliosQuery,
  useGetAssetSectorsQuery,
  useGetAddressAssetScoreQuery,
  useGetSubportfolioAssetLocationsMutation,
  useGetPortfolioAssetLocationsMutation,
} = api
