import { createTheme } from '@mui/material'
// import { green, purple } from '@mui/material/colors';

const primary = '#97b6e5'
const secondary = '#cb5e7b'

export { primary, secondary }

const theme = createTheme({
  typography: {
    fontFamily: ['Inter'].join(','),
    body1: {
      fontSize: '0.9rem',
    },
    body2: {
      fontSize: '0.65rem',
    },
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
})

export default theme
