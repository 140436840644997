import * as React from 'react'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import ColorNumber from 'UI/ColorNumber'
import useDifferenceType from 'hooks/useDifferenceType'
import { path, equals } from 'ramda'
import { TableCell } from '@mui/material'
import LoadingValue from '../LoadingValue'
import useFocusAddress from 'hooks/useFocusAddress'
import { DatapointValue } from 'typescript-types'
import { displayCellData } from 'utils/math'

type DataCellProps = {
  selectPath: string[]
  sectionName: string
  filterName: string
  showAbsoluteValues?: boolean
}

const tableCellStyle = {
  padding: 1,
}

const DataCell: React.FC<DataCellProps> = ({
  selectPath,
  filterName,
  showAbsoluteValues,
}) => {
  const focus = useFocusAddress()
  const asset = useSelector((state: RootState) => {
    return path(selectPath, state) as DatapointValue | undefined
  }, equals)

  let value = asset?.value?.toFixed(1)

  const focusAsset = useSelector(
    (state: RootState) =>
      state.datapoints?.[`${focus?.lat};${focus?.long}`]?.[filterName]?.value,
    equals
  )

  const attentType = useDifferenceType(
    focusAsset,
    asset?.value,
    showAbsoluteValues
  )

  const differenceText = React.useMemo(() => {
    const focusAssetScore = Number(focusAsset)
    const assetScore = Number(asset?.value)

    // don't show difference
    if (
      Math.abs(focusAssetScore - assetScore) < 1 ||
      !focusAssetScore ||
      !assetScore ||
      showAbsoluteValues
    ) {
      return undefined
    }
    if (focusAssetScore > assetScore) {
      return `-${displayCellData(Math.abs(focusAssetScore - assetScore), 1)}`
    }

    return `+${displayCellData(Math.abs(focusAssetScore - assetScore), 1)}`
  }, [asset, focusAsset, showAbsoluteValues])

  return (
    <TableCell align='left' sx={tableCellStyle}>
      {
        <LoadingValue value={asset}>
          <ColorNumber
            differenceText={differenceText}
            value={String(value || '')}
            variant={attentType}
          />
        </LoadingValue>
      }
    </TableCell>
  )
}

export default React.memo(DataCell)
