import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Box,
} from '@mui/material'
import { useToast } from 'contexts/AlertProvider'
import { useInsertFilterPresetMutation } from 'services'
import { useSelector } from 'react-redux'
import type { RootState } from 'store'
import CommonButton from 'UI/CommonButton'
import type { AlertState } from 'contexts/AlertProvider'
import CommonTextField from 'UI/CommonTextField'
import * as React from 'react'

type NewPresetDialogProps = {
  open: boolean
  onClose: () => void
}

const SAVE_ALERT_STATE = {
  severity: 'success',
  message: 'Saved filter preset',
} as AlertState

const SAVE_ERROR_ALERT_STATE = {
  severity: 'error',
  message: 'Something went wrong saving filter preset',
} as AlertState

const NewPresetDialog: React.FC<NewPresetDialogProps> = ({ open, onClose }) => {
  const portfolios = useSelector(
    (state: RootState) => state.assetView.portfolio
  )
  const subportfolios = useSelector(
    (state: RootState) => state.assetView.subportfolio
  )
  const addresses = useSelector((state: RootState) => state.assetView.address)
  const focusAddress = useSelector(
    (state: RootState) => state.assetView.focusAddress
  )
  const [insert] = useInsertFilterPresetMutation()
  const setToast = useToast()
  const [presetName, setPresetName] = React.useState('')
  const filterState = useSelector((state: RootState) => state.filter)
  const changePresetName = React.useCallback(
    (e) => setPresetName(e.target.value),
    []
  )

  const save = React.useCallback(() => {
    const filters: string[] = []
    const sections: string[] = []

    Object.entries(filterState.filters).forEach(([filterName, value]) => {
      if (value) {
        filters.push(filterName)
      }
    })

    Object.entries(filterState.sections).forEach(([sectionName, value]) => {
      if (value) {
        sections.push(`${sectionName}`)
      }
    })

    insert({
      name: presetName,
      preset: {
        portfolios: portfolios,
        subportfolios,
        focusAddress,
        addresses,
        filters,
        sections,
      },
    })
      .unwrap()
      .then(() => setToast(SAVE_ALERT_STATE))
      .catch(() => setToast(SAVE_ERROR_ALERT_STATE))
      .finally(onClose)
  }, [
    filterState,
    presetName,
    insert,
    onClose,
    setToast,
    addresses,
    focusAddress,
    portfolios,
    subportfolios,
  ])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ fontWeight: 'bold' }}>Save filter preset</DialogTitle>
      <DialogContent>
        <Typography>
          Save your current selection of filters so you can load them and re-use
          them at a later time.
        </Typography>
        <Box paddingTop={2}>
          <CommonTextField
            placeholder='Preset name'
            value={presetName}
            onChange={changePresetName}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <CommonButton color='secondary' onClick={onClose}>
          cancel
        </CommonButton>
        <CommonButton variant='contained' onClick={save} disabled={!presetName}>
          save
        </CommonButton>
      </DialogActions>
    </Dialog>
  )
}

export default NewPresetDialog
