import { Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/system/Box'
import { useGetUserQuery } from 'services'
import Logo from '../../assets/cropped-logo.png'
import UserMenu from './UserMenu'

export const Header = () => {
  const { data } = useGetUserQuery()

  return (
    <AppBar color='secondary'>
      <Toolbar>
        <Box
          alignItems='center'
          width='100%'
          display='flex'
          justifyContent='space-between'
        >
          <Box flexGrow={1} display='flex' alignItems='center'>
            <img height='24px' src={Logo} />
            <Typography
              fontSize='0.7rem'
              paddingLeft={1}
              display='flex'
              alignItems='center'
              fontWeight={900}
            >
              {data?.tenant?.name}
            </Typography>
          </Box>
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  )
}
