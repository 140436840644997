import * as React from 'react'
import { Box } from '@mui/material'
import { Paper } from '@mui/material'
import { RootState } from 'store'
import ClusterDialog from './ClusterDialog'
import useOpenAndClose from 'hooks/useOpenAndClose'
import TableSectionHeader from 'UI/TableSectionHeader'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHeader from 'UI/TableHeader'
import useHeaderTitles from 'hooks/useHeaderTitles'
import useOrderedColumns from 'hooks/useOrderedColumns'
import DeleteAddressDialog from './DeleteAddressDialog'
import DeleteFocusDialog from './DeleteFocusDialog'
import DeletePortfolioDialog from './DeletePortfolioDialog'
import DeleteSubportfolioDialog from './DeleteSubportfolioDialog'
import DataRow from './DataRow'
import ScoreRow from './ScoreRow'
import AddAddressDialog from '../AddAddressDialog'
import AddPortfolioDialog from '../AddPortfolioDialog'
import AddSpeedDial from '../AddSpeedDial'
import NumberOfAssetsRow from './NumberOfAssetsRow'
import { useDispatch, useSelector } from 'react-redux'
import type { FC } from 'react'
import type { Column } from 'hooks/useOrderedColumns'
import type { Config, Portfolio, Subportfolio } from 'typescript-types'
import { setClusterKeys } from 'reducers/clusterView'
import useSortedFilterConfigQuery from 'hooks/useSortedFilterConfigQuery'

export type DataTableProps = {
  config: Config[0]
  columns: Column[]
}

const showScore = (sectionName: string) => sectionName === 'nuts3'

const DataTable: FC<DataTableProps> = React.memo(({ config, columns }) => {
  const children = useSelector((state: RootState) =>
    config.subclusters.filter(
      ({ id: subclusterId }) => !!state.filter.filters?.[subclusterId]
    )
  )

  return (
    <TableBody>
      <TableSectionHeader
        title={config.displayName}
        numberOfColumns={columns.length}
      />
      {showScore(config.name) && <ScoreRow columns={columns} />}
      {children.map(
        ({
          displayName,
          id: subclusterId,
          subclusterDatapointId,
          datapoints,
          showAbsoluteValues,
        }) => (
          <DataRow
            expandable
            key={subclusterId}
            sectionName={config.id}
            filterName={subclusterDatapointId}
            displayName={displayName}
            columns={columns}
            children={datapoints}
            showAbsoluteValues={showAbsoluteValues}
          />
        )
      )}
    </TableBody>
  )
})

const DataView: FC<{ drawerOpen: boolean }> = ({ drawerOpen }) => {
  const columns = useOrderedColumns()
  const [
    isDeleteFocusDialogOpen,
    openDeleteFocusDialog,
    closeDeleteFocusDialog,
  ] = useOpenAndClose()
  const [addressToDelete, setAddressToDelete] =
    React.useState<{ id: string; lat: string; long: string; address: string }>()
  const closeDeleteAddressDialog = React.useCallback(
    () => setAddressToDelete(undefined),
    []
  )
  const openDeleteAddressDialog = React.useCallback(
    (address) => setAddressToDelete(address),
    []
  )
  const [portfolioToDelete, setPortfolioToDelete] = React.useState<Portfolio>()
  const closeDeletePortfolioDialog = React.useCallback(
    () => setPortfolioToDelete(undefined),
    []
  )
  const openDeletePortfolioDialog = React.useCallback(
    (portfolio: Portfolio) => setPortfolioToDelete(portfolio),
    []
  )
  const [subportfolioToDelete, setSubportfolioToDelete] =
    React.useState<Subportfolio>()
  const closeDeleteSubPortfolioDialog = React.useCallback(
    () => setSubportfolioToDelete(undefined),
    []
  )
  const openDeleteSubportfolioDialog = React.useCallback(
    (subportfolio: Subportfolio) => setSubportfolioToDelete(subportfolio),
    []
  )

  const headerTitles = useHeaderTitles(columns, {
    openDeleteFocusDialog,
    openDeleteAddressDialog,
    openDeletePortfolioDialog,
    openDeleteSubportfolioDialog,
  })

  const sortedConfig = useSortedFilterConfigQuery()

  const tableMaxWidth = React.useMemo(
    () => (drawerOpen ? 'calc(100vw - 384px)' : 'calc(100vw - 64px)'),
    [drawerOpen]
  )

  const tableContainerStyle = React.useMemo(
    () => ({
      maxWidth: tableMaxWidth,
      maxHeight: 'calc(100vh - 128px)',
      width: 'fit-content',
    }),
    [tableMaxWidth]
  )

  const clusterDialogOpen = useSelector(
    (state: RootState) => state.clusterView.open
  )
  const dispatch = useDispatch()
  const onCloseClusterDialog = React.useCallback(
    () => dispatch(setClusterKeys({ open: false })),
    [dispatch]
  )
  const clusterDialogState = useSelector(
    (state: RootState) => state.clusterView.state
  )

  const [
    addPortfolioDialogOpen,
    openAddPortfolioDialog,
    closeAddPortfolioDialog,
  ] = useOpenAndClose()

  const [addAddressDialogOpen, openAddAddressDialog, closeAddAddressDialog] =
    useOpenAndClose()

  return (
    <>
      <Box display='flex' flexGrow={1} flex={1} padding={1}>
        <TableContainer sx={tableContainerStyle} component={Paper}>
          <Table stickyHeader>
            <TableHeader columnTitles={headerTitles} />
            <TableBody>
              <NumberOfAssetsRow columns={columns} />
            </TableBody>
            {sortedConfig.map((configEntry) => (
              <DataTable
                columns={columns}
                key={configEntry.id}
                config={configEntry}
              />
            ))}
          </Table>
        </TableContainer>
      </Box>
      <ClusterDialog
        columns={columns}
        open={clusterDialogOpen}
        onClose={onCloseClusterDialog}
        {...clusterDialogState}
      />
      <DeleteFocusDialog
        open={isDeleteFocusDialogOpen}
        onClose={closeDeleteFocusDialog}
      />
      <DeleteAddressDialog
        open={Boolean(addressToDelete)}
        onClose={closeDeleteAddressDialog}
        {...addressToDelete}
      />
      <DeletePortfolioDialog
        open={Boolean(portfolioToDelete)}
        onClose={closeDeletePortfolioDialog}
        portfolio={portfolioToDelete}
      />
      <DeleteSubportfolioDialog
        open={Boolean(subportfolioToDelete)}
        onClose={closeDeleteSubPortfolioDialog}
        subportfolio={subportfolioToDelete}
      />
      <AddSpeedDial
        openAddAddressDialog={openAddAddressDialog}
        openAddPortfolioDialog={openAddPortfolioDialog}
      />
      <AddPortfolioDialog
        open={addPortfolioDialogOpen}
        onClose={closeAddPortfolioDialog}
      />
      <AddAddressDialog
        open={addAddressDialogOpen}
        onClose={closeAddAddressDialog}
      />
    </>
  )
}

export default React.memo(DataView)
