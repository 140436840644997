import * as React from 'react'
import { Accordion, Box, ListItem, Typography, Tooltip } from '@mui/material'
import CommonTextField from 'UI/CommonTextField'
import CommonButton from 'UI/CommonButton'
import type { FC } from 'react'
import useOpenAndClose from 'hooks/useOpenAndClose'
import { InputAdornment } from '@mui/material'
import useSortedFilterConfigQuery from 'hooks/useSortedFilterConfigQuery'
import AccordionSummary from 'UI/AccordionSummary'
import NewPresetDialog from './NewPresetDialog'
import LoadPresetDialog from './LoadPresetDialog'
import FilterListIcon from '@mui/icons-material/FilterList'
import LeafCheckbox from './LeafCheckbox'
import ParentCheckbox from './ParentCheckbox'
import { Config, Subcluster } from 'typescript-types'

const Children: FC<{ subclusters: Subcluster[]; sectionId: string }> = ({
  subclusters,
  sectionId,
}) => {
  return (
    <>
      {subclusters.map((subcluster) => {
        return (
          <ListItem key={subcluster.id} sx={{ padding: 0 }}>
            <Box display='flex' alignItems='center'>
              <LeafCheckbox id={subcluster.id} sectionId={sectionId} />

              <Typography variant='body2' sx={{ fontSize: '0.7rem' }}>
                {subcluster.displayName}
              </Typography>
            </Box>
          </ListItem>
        )
      })}
    </>
  )
}

const FilterSection = ({
  config,
  search,
}: {
  config: Config[0]
  search: string
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const children = React.useMemo(
    () =>
      config.subclusters.filter(({ name }) =>
        name.toLowerCase().includes(search)
      ),
    [config, search]
  )

  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setIsOpen(isExpanded)
    },
    []
  )

  React.useEffect(() => {
    setIsOpen(true)
  }, [search])

  return (
    <Accordion
      expanded={isOpen}
      onChange={handleChange}
      TransitionProps={{ timeout: 150 }}
      elevation={0}
      disableGutters
      sx={{
        padding: 0,
        minHeight: 0,
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary>
        <Box display='flex' alignItems='center'>
          <ParentCheckbox id={config.id} subclusters={children} />
          <Typography variant='body2' sx={{ fontSize: '0.7rem' }}>
            {config.displayName}
          </Typography>
        </Box>
      </AccordionSummary>
      <Box paddingLeft={1}>
        <Children sectionId={config.id} subclusters={children} />
      </Box>
    </Accordion>
  )
}

var Filter = () => {
  const sortedConfig = useSortedFilterConfigQuery()

  const [newPresetDialogOpen, openNewPresetDialog, closeNewPresetDialog] =
    useOpenAndClose()
  const [loadPresetDialogOpen, openLoadPresetDialog, closeLoadPresetDialog] =
    useOpenAndClose()

  const [search, setSearch] = React.useState<string>('')
  const onChangeFilterInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
    []
  )

  return (
    <>
      <NewPresetDialog
        open={newPresetDialogOpen}
        onClose={closeNewPresetDialog}
      />
      <LoadPresetDialog
        open={loadPresetDialogOpen}
        onClose={closeLoadPresetDialog}
      />
      <CommonTextField
        placeholder='filter'
        value={search}
        onChange={onChangeFilterInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <FilterListIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          width: '100%',
          maxWidth: 460,
          bgcolor: 'background.paper',
          height: 'calc(100vh - 110px)',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {sortedConfig.length > 0 ? (
          <form>
            {sortedConfig.map((config) => (
              <FilterSection
                search={search.toLowerCase()}
                config={config}
                key={config.displayName}
              />
            ))}
          </form>
        ) : (
          <div />
        )}
      </Box>
      <Box
        display='flex'
        justifyContent='space-around'
        sx={{ backgroundColor: '#bbbbbb29', borderTop: '1px solid grey' }}
      >
        <Tooltip title='Save the current state of filters to be able to re-use it later.'>
          <div>
            <CommonButton
              color='secondary'
              variant='contained'
              onClick={openNewPresetDialog}
            >
              Save filters
            </CommonButton>
          </div>
        </Tooltip>
        <Tooltip title='Load a preset of filters.'>
          <div>
            <CommonButton
              color='secondary'
              variant='contained'
              onClick={openLoadPresetDialog}
            >
              Load filters
            </CommonButton>
          </div>
        </Tooltip>
      </Box>
    </>
  )
}

export default React.memo(Filter)
