import * as React from 'react'
import { TableCell, Typography } from '@mui/material'

type TextCellProps = {
  text: string
  onClick?: () => void
}

const TextCell: React.FC<TextCellProps> = ({ text, onClick }) => {
  const tableCellStyle = React.useMemo(
    () => ({
      verticalAlign: 'center',
      padding: 1,
      height: '32px',
      cursor: onClick ? 'pointer' : undefined,
    }),
    [onClick]
  )

  return (
    <TableCell onClick={onClick} sx={tableCellStyle}>
      <Typography variant='body2'>{text}</Typography>
    </TableCell>
  )
}

export default React.memo(TextCell)
