import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import HomeView from 'views/Home'
import LoginView from 'views/Login'
import { Paths } from './paths'
import PrivateRoute from './PrivateRoute'
import React from 'react'

const Redirect: React.FC<{ url: string }> = ({ url }) => {
  const navigate = useNavigate()
  React.useEffect(() => {
    navigate(url)
  }, [navigate, url])

  return <React.Fragment />
}

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<PrivateRoute />}>
          <Route path={`${Paths.dataView}`} element={<HomeView />} />
          <Route path='*' element={<Redirect url='' />} />
        </Route>
        <Route path='/login' element={<LoginView />} />
      </Routes>
    </BrowserRouter>
  )
}

export default React.memo(Router)
