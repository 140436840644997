import { Box, TextField } from '@mui/material'
import React from 'react'
import type { TextFieldProps } from '@mui/material'

const CommonTextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ inputProps, ...props }, ref) => (
    <Box padding={1}>
      <TextField
        ref={ref}
        size='small'
        {...props}
        inputProps={{
          ...inputProps,
          autoComplete: 'disabled',
          form: {
            autocomplete: 'off',
          },
        }}
      />
    </Box>
  )
)

export default CommonTextField
