import { useSelector } from 'react-redux'
import type { RootState } from 'store'

const useFocusAddress = () => {
  const focusAddress = useSelector(
    (state: RootState) => state.assetView.focusAddress
  )

  return focusAddress
}

export default useFocusAddress
