import { fromPairs, mergeDeepRight } from 'ramda'
import type { DatapointValue } from 'typescript-types'

type DataResponse = Record<
  string,
  Record<DatapointValue['id'], DatapointValue | null>
>

export const setMissingValuesToNull = (
  firstLevelKeys: string[],
  secondLevelKeys: string[],
  response: DataResponse
) => {
  const emptyDefault: DataResponse = {}

  firstLevelKeys.forEach((element) => {
    const emptyPairs: Record<DatapointValue['id'], DatapointValue | null> =
      fromPairs(secondLevelKeys.map((filter) => [filter, null]))
    emptyDefault[element] = emptyPairs
  })

  return mergeDeepRight(emptyDefault, response)
}
