import { API_BASE_URL, fetchJson } from 'utils/network'
import { AppThunk } from 'store'
import { addData } from 'reducers/datapoints'
import { getToken } from 'utils/auth'
import type { Subportfolio, DatapointValue } from 'typescript-types'
import { createErrorState } from 'thunks/createErrorState'
import { setMissingValuesToNull } from 'thunks/utils'

const fetchSubportfolioData =
  (
    subportfolio: Subportfolio,
    newFilters: string[]
  ): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      if (newFilters.length === 0) {
        return true
      }

      const filterQuery = newFilters
        .map((filter) => {
          return `id=${encodeURIComponent(filter)}`
        })
        .join('&')
      const tk = getToken()

      const url = `${API_BASE_URL}/data/portfolios/${subportfolio.portfolioId}/subportfolios/datapoints/?${filterQuery}&sector=${subportfolio.sector}&country=${subportfolio.country}&tk=${tk}`
      const response = (await fetchJson(url)) as Record<
        DatapointValue['id'],
        DatapointValue
      >

      dispatch(
        addData(
          setMissingValuesToNull([subportfolio.id], newFilters, {
            [subportfolio.id]: response,
          })
        )
      )
      return true
    } catch (e) {
      const errorState = createErrorState([subportfolio.id], newFilters)
      dispatch(addData(errorState))
      console.error('e', e)
      return false
    }
  }

export default fetchSubportfolioData
