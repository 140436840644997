import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { Portfolio, Subportfolio, Address } from 'typescript-types'
import { omit } from 'ramda'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

type AssetViewState = {
  portfolio: Record<string, Portfolio & { color: string }>
  subportfolio: Record<string, Subportfolio & { color: string }>
  address: Record<string, Address>
  focusAddress?: Address
}

const initialState: AssetViewState = {
  portfolio: {},
  subportfolio: {},
  address: {},
}

const assetViewSlice = createSlice({
  name: 'assetView',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<AssetViewState>) => action.payload,
    reset: () => initialState,
    focusAddress: (state, action: PayloadAction<Address>) => {
      const newAddress: AssetViewState['address'] = omit(
        [action.payload.id],
        state.address
      )
      if (state.focusAddress) {
        newAddress[state.focusAddress.id] = state.focusAddress
      }

      return {
        ...state,
        focusAddress: action.payload,
        address: newAddress,
      }
    },
    setFocusAddress: (state, action: PayloadAction<Address | undefined>) => ({
      ...state,
      focusAddress: action.payload,
    }),
    addSubportfolio: (
      state,
      action: PayloadAction<Subportfolio & { color: string }>
    ) => ({
      ...state,
      subportfolio: {
        ...state.subportfolio,
        [action.payload.id]: action.payload,
      },
    }),
    addPortfolio: (
      state,
      action: PayloadAction<Portfolio & { color: string }>
    ) => ({
      ...state,
      portfolio: {
        ...state.portfolio,
        [action.payload.id]: action.payload,
      },
    }),
    addAddress: (state, action: PayloadAction<Address>) => ({
      ...state,
      address: {
        ...state.address,
        [action.payload.id]: action.payload,
      },
    }),
    deletePortfolio: (state, action: PayloadAction<string>) => ({
      ...state,
      portfolio: omit([action.payload], state.portfolio),
    }),
    deleteSubportfolio: (state, action: PayloadAction<string>) => ({
      ...state,
      subportfolio: omit([action.payload], state.subportfolio),
    }),
    deleteAddress: (state, action: PayloadAction<string>) => ({
      ...state,
      address: omit([action.payload], state.address),
    }),
  },
})

export const {
  setState,
  focusAddress,
  reset,
  setFocusAddress,
  addSubportfolio,
  addPortfolio,
  addAddress,
  deletePortfolio,
  deleteAddress,
  deleteSubportfolio,
} = assetViewSlice.actions

const persistConfig = {
  key: assetViewSlice.name,
  storage,
}

export const persistedReducer = persistReducer(
  persistConfig,
  assetViewSlice.reducer
)

export default assetViewSlice.reducer
