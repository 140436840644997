import * as React from 'react'
import {
  useGetAddressAssetScoreQuery,
  useGetPortfolioAssetScoreQuery,
  useGetSubportfolioAssetScoreQuery,
} from 'services'
import TableRow from '@mui/material/TableRow'
import SmallHeaderCell from 'UI/SmallHeaderCell'
import { TableCell } from '@mui/material'
import LoadingValue from '../LoadingValue'
import type { DataTableProps } from '..'
import ColorNumber from 'UI/ColorNumber'
import { AttentionType } from 'UI/ColorNumber'
import { ColumnVariant } from 'hooks/useOrderedColumns'
import { displayFloat } from 'utils/math'

const ScoreCell: React.FC = ({ children }) => {
  return (
    <TableCell
      sx={{
        verticalAlign: 'center',
        padding: 1,
        height: '32px',
      }}
    >
      {children}
    </TableCell>
  )
}

const AddressScoreCell: React.FC<{ latlong: string; sector: string }> = ({
  latlong,
  sector,
}) => {
  const { data } = useGetAddressAssetScoreQuery(
    { latlong, sector },
    {
      skip: !latlong,
    }
  )

  return (
    <ScoreCell>
      <LoadingValue value={data?.score}>
        <ColorNumber
          variant={AttentionType.Similar}
          value={data?.score ? displayFloat(data.score, 1) : ''}
        />
      </LoadingValue>
    </ScoreCell>
  )
}

const PortfolioScoreCell: React.FC<{ id?: string; name: string }> = ({
  id,
}) => {
  const { data } = useGetPortfolioAssetScoreQuery(id as string, { skip: !id })

  return (
    <ScoreCell>
      <LoadingValue value={data?.score}>
        <ColorNumber
          variant={AttentionType.Similar}
          value={data?.score ? displayFloat(data.score, 1) : ''}
        />
      </LoadingValue>
    </ScoreCell>
  )
}

const SubportfolioScoreCell: React.FC<{
  portfolioId?: string
  name: string
  sector: string
  country: string
}> = ({ portfolioId, sector, country }) => {
  const { data } = useGetSubportfolioAssetScoreQuery(
    { portfolioId: portfolioId as string, country, sector },
    {
      skip: !portfolioId,
    }
  )

  return (
    <ScoreCell>
      <LoadingValue value={data?.score}>
        <ColorNumber
          variant={AttentionType.Similar}
          value={data?.score ? displayFloat(data.score, 1) : ''}
        />
      </LoadingValue>
    </ScoreCell>
  )
}

const ScoreRow: React.FC<Pick<DataTableProps, 'columns'>> = ({ columns }) => {
  return (
    <TableRow>
      <SmallHeaderCell text='NUTs3 Score' />
      {columns.map(({ data, variant }, idx) => {
        switch (variant) {
          case ColumnVariant.Focus:
            return (
              <AddressScoreCell
                key={`${data.lat};${data.long}-${idx}`}
                sector={data.assetType}
                latlong={`${data.lat};${data.long}`}
              />
            )
          case ColumnVariant.Address:
            return (
              <AddressScoreCell
                key={`${data.lat};${data.long}-${idx}`}
                sector={data.assetType}
                latlong={`${data.lat};${data.long}`}
              />
            )
          case ColumnVariant.Portfolio:
            return <PortfolioScoreCell key={`${data.id}-${idx}`} {...data} />
          case ColumnVariant.Subportfolio:
            return <SubportfolioScoreCell key={`${data.id}-${idx}`} {...data} />
        }
      })}
    </TableRow>
  )
}

export default React.memo(ScoreRow)
