import * as React from 'react'
import { useGetPortfolioAssetScoresByFilterQuery } from 'services'
import type { PortfolioDataCellProps } from '../PortfolioTypeDataCell'
import PortfolioTypeDataCell from '../PortfolioTypeDataCell'

const PortfolioCell: React.FC<
  Omit<PortfolioDataCellProps, 'data' | 'isGraphLoading'>
> = ({
  id,
  filterName,
  sectionName,
  expanded,
  expandable,
  setExpanded,
  showAbsoluteValues,
  divideValueBy,
}) => {
  const { data, isLoading: isGraphLoading } =
    useGetPortfolioAssetScoresByFilterQuery(
      {
        id,
        datapointId: filterName,
      },
      {
        skip: !expanded,
      }
    )

  return (
    <PortfolioTypeDataCell
      divideValueBy={divideValueBy}
      id={id}
      isGraphLoading={isGraphLoading}
      filterName={filterName}
      sectionName={sectionName}
      data={data || []}
      expandable={expandable}
      expanded={expanded}
      setExpanded={setExpanded}
      showAbsoluteValues={showAbsoluteValues}
    />
  )
}

export default PortfolioCell
