import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { equals } from 'ramda'

type UseLatlongs = {
  id: string
  lat: string
  long: string
  address: string
  assetType: string
}[]

const useLatlongs = (): UseLatlongs => {
  return useSelector(
    (state: RootState) => Object.values(state.assetView.address),
    equals
  )
}

export default useLatlongs
