import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { equals } from 'ramda'

const useSubportfolios = () => {
  return useSelector(
    (state: RootState) => Object.values(state.assetView.subportfolio),
    equals
  )
}

export default useSubportfolios
