import * as React from 'react'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { useToast } from 'contexts/AlertProvider'
import type { SerializedError } from '@reduxjs/toolkit'

const defaultErrorMessage = 'Something unexpected went wrong, please try again'
const defaultSuccessMessage = 'Operation was succesfull'

const useAlertEffect = ({
  errorMessage,
  error,
  successMessage,
  data,
}: {
  errorMessage?: string
  error?: FetchBaseQueryError | SerializedError
  successMessage?: string
  data?: any
}) => {
  const sendToast = useToast()

  React.useEffect(() => {
    if (error) {
      sendToast({
        severity: 'error',
        message: errorMessage || defaultErrorMessage,
      })
    }
  }, [errorMessage, error, sendToast])

  React.useEffect(() => {
    if (data) {
      sendToast({
        severity: 'success',
        message: successMessage || defaultSuccessMessage,
      })
    }
  }, [successMessage, data, sendToast])
}

export default useAlertEffect
