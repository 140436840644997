import { Box, Card, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import CommonButton from 'UI/CommonButton'
import CommonTextField from 'UI/CommonTextField'
import React from 'react'
import type { FC } from 'react'
import { setToken } from 'utils/auth'
import { useLazyLoginQuery } from 'services'
import useAlertEffect from 'hooks/useAlertEffect'
import { useGetFilterConfigQuery } from '../../services'

const useInitialFilters = () => {
  const { data } = useGetFilterConfigQuery()

  const nuts3 = React.useMemo(
    () => data?.find(({ name }) => name === 'nuts3'),
    [data]
  )

  if (nuts3) {
    return [
      `section=${nuts3.id}`,
      ...nuts3.subclusters.map(({ id }) => `filter=${encodeURIComponent(id)}`),
    ].join('&')
  }
  return ''
}

const LoginView: FC = () => {
  const initialFilters = useInitialFilters()
  const [loginQuery, { error }] = useLazyLoginQuery()
  useAlertEffect({
    error,
    errorMessage: 'Something went wrong logging in. Check your credentials.',
  })
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = React.useMemo(
    () =>
      handleSubmit(({ email, password }) => {
        loginQuery({ email, password })
          .unwrap()
          .then(({ token }) => {
            setToken(token)
            navigate(`/?${initialFilters}`)
          })
      }),
    [initialFilters, loginQuery, handleSubmit, navigate]
  )

  return (
    <Box
      width='100vw'
      height='100vh'
      display='flex'
      justifyContent='center'
      alignItems='center'
      sx={{
        background:
          'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(203,94,123,1) 98%, rgba(203,94,123,1) 100%)',
      }}
    >
      <Card>
        <Box display='flex' flexDirection='column' maxWidth={300}>
          <form onSubmit={onSubmit} autoComplete='off'>
            <Box margin={2} padding={1} borderRadius={3}>
              <Typography sx={{ padding: 1 }} fontWeight='bold' variant='h6'>
                Login
              </Typography>
              <CommonTextField
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : undefined}
                label='Username'
                variant='filled'
                {...register('email', { required: 'Username is required' })}
              />
              <CommonTextField
                error={!!errors.password}
                helperText={
                  errors.email ? errors?.password?.message : undefined
                }
                type='password'
                label='Password'
                variant='filled'
                {...register('password', { required: 'Password is required' })}
              />
              <CommonButton
                variant='contained'
                color='secondary'
                autoFocus
                fullWidth
                type='submit'
              >
                Login
              </CommonButton>
            </Box>
          </form>
        </Box>
      </Card>
    </Box>
  )
}

export default React.memo(LoginView)
