import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { pathOr } from 'ramda'

type Filter = string
type SectionName = string

type AssetType = 'portfolio' | 'subportfolio' | 'address'
type AssetSection = Record<
  string,
  Record<SectionName, Record<Filter, number | null>>
>

export type AssetDataState = {
  portfolio: AssetSection
  subportfolio: AssetSection
  address: AssetSection
}

const initialState: AssetDataState = {
  portfolio: {},
  subportfolio: {},
  address: {},
}

const assetDataSlice = createSlice({
  name: 'assetData',
  initialState,
  reducers: {
    reset: () => initialState,
    addData: (
      state,
      action: PayloadAction<{
        assetType: AssetType
        id: string
        sectionName: string
        data: Record<Filter, number | null>
      }>
    ) => ({
      ...state,
      [action.payload.assetType]: {
        ...state[action.payload.assetType],
        [action.payload.id]: {
          ...pathOr({}, [action.payload.assetType, action.payload.id], state),
          [action.payload.sectionName]: {
            ...pathOr(
              {},
              [
                action.payload.assetType,
                action.payload.id,
                action.payload.sectionName,
              ],
              state
            ),
            ...action.payload.data,
          },
        },
      },
    }),
  },
})

export const { addData, reset } = assetDataSlice.actions

export default assetDataSlice.reducer
