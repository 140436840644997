import * as React from 'react'
import { useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import useSyncSearchParams from '../../../hooks/useSyncSearchParams'
import type { RootState } from '../../../store'
import type { TParentCheckbox } from './types'

const stopPropagation = (e: any) => e.stopPropagation()

const ParentCheckbox: React.FC<TParentCheckbox> = ({ subclusters, id }) => {
  const value = useSelector((state: RootState) => !!state.filter.sections[id])
  const syncSearchParamsWithState = useSyncSearchParams()

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { searchParams } = new URL(document.location.href)
      if (e.target.checked) {
        searchParams.append('section', id)
        subclusters.forEach(({ id: subclusterId }) => {
          searchParams.append(`filter-${id}`, subclusterId)
        })

        window.history.replaceState(
          {},
          '',
          `${document.location.pathname}?${searchParams}`
        )
      } else {
        const filterMap: Record<string, boolean> = {}

        subclusters.forEach(({ id: subclusterId }) => {
          filterMap[subclusterId] = true
        })

        const newSearchParams = new URLSearchParams()
        searchParams.forEach((searchParamValue, key) => {
          if (
            searchParamValue !== id &&
            !(key === `filter-${id}` && filterMap[searchParamValue])
          ) {
            newSearchParams.append(key, searchParamValue)
          }
        })
        window.history.replaceState(
          {},
          '',
          `${document.location.pathname}?${newSearchParams}`
        )
      }

      syncSearchParamsWithState()
    },
    [id, subclusters, syncSearchParamsWithState]
  )

  return (
    <Checkbox
      checked={value}
      onChange={onChange}
      onClick={stopPropagation}
      size='small'
    />
  )
}

export default ParentCheckbox
