import * as React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Divider,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { deleteAddress, focusAddress } from 'reducers/assetView'
import CommonButton from 'UI/CommonButton'

const dialogContentStyle = { minWidth: 300, maxWidth: 300, padddingTop: 8 }
const dialogTitleStyle = { fontWeight: 'bold' }

type DeleteAddressDialogProps = {
  onClose: () => void
  open: boolean
  id?: string
  lat?: string
  long?: string
  address?: string
  assetType?: string
}

const DeleteAddressDialog: React.FC<DeleteAddressDialogProps> = ({
  onClose,
  open,
  lat,
  id,
  long,
  address,
  assetType,
}) => {
  const dispatch = useDispatch()
  const commitDeleteAddress = React.useCallback(() => {
    if (id) {
      dispatch(deleteAddress(id))
      onClose()
    }
  }, [dispatch, id, onClose])

  const commitFocusAddress = React.useCallback(() => {
    if (id && lat && address && long && assetType) {
      dispatch(
        focusAddress({
          id,
          lat,
          long,
          address,
          assetType,
        })
      )
    }
  }, [id, lat, long, assetType, dispatch, address])

  return (
    <Dialog
      maxWidth='sm'
      open={open && Boolean(lat) && Boolean(long) && Boolean(address)}
      onClose={onClose}
    >
      <DialogTitle sx={dialogTitleStyle}>Address</DialogTitle>
      <DialogContent sx={dialogContentStyle}>
        <Typography>
          Delete <i>{address}</i> from the overview.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'row-reverse' }}>
        <CommonButton onClick={onClose} variant='contained' color='primary'>
          close
        </CommonButton>
        <CommonButton
          onClick={commitDeleteAddress}
          variant='contained'
          color='secondary'
        >
          Delete
        </CommonButton>
      </DialogActions>
      <Divider />
      <DialogContent sx={dialogContentStyle}>
        <Typography>
          Make <i>{address}</i> the focus address.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'row-reverse' }}>
        <CommonButton onClick={onClose} variant='contained' color='primary'>
          close
        </CommonButton>
        <CommonButton
          onClick={commitFocusAddress}
          variant='contained'
          color='secondary'
        >
          Make focus address
        </CommonButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteAddressDialog
