import * as React from 'react'
import { Subportfolio } from 'typescript-types'
import { useGetSubportfolioAssetScoresByFilterQuery } from 'services'
import type { PortfolioDataCellProps } from '../PortfolioTypeDataCell'
import PortfolioTypeDataCell from '../PortfolioTypeDataCell'

const SubportfolioCell: React.FC<
  Omit<PortfolioDataCellProps, 'data' | 'isGraphLoading'> & {
    subportfolio: Subportfolio
  }
> = ({
  id,
  filterName,
  sectionName,
  subportfolio,
  expanded,
  setExpanded,
  expandable,
  showAbsoluteValues,
  divideValueBy,
}) => {
  const { data, isLoading: isGraphLoading } =
    useGetSubportfolioAssetScoresByFilterQuery(
      {
        id: subportfolio.portfolioId,
        datapointId: filterName,
        sector: subportfolio.sector,
        country: subportfolio.country,
      },
      {
        skip: !expanded,
      }
    )

  return (
    <PortfolioTypeDataCell
      id={id}
      showAbsoluteValues={showAbsoluteValues}
      isGraphLoading={isGraphLoading}
      filterName={filterName}
      sectionName={sectionName}
      data={data || []}
      expandable={expandable}
      expanded={expanded}
      setExpanded={setExpanded}
      subportfolioMode
      divideValueBy={divideValueBy}
    />
  )
}

export default SubportfolioCell
