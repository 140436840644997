import * as React from 'react'
import useFocusAddress from 'hooks/useFocusAddress'
import { Box, Skeleton } from '@mui/material'
import { RootState } from 'store'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import NormalDist from 'components/NormalDist'
import { Accordion } from '@mui/material'
import { uniq, equals } from 'ramda'
import TableCell from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useDifferenceType from 'hooks/useDifferenceType'
import LoadingValue from '../LoadingValue'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { AccordionDetails } from '@mui/material'
import ColorNumber from 'UI/ColorNumber'
import { TABLE_CELL_WIDTH } from '../../../../constants'
import type { DatapointValue } from 'typescript-types'
import { displayCellData } from 'utils/math'

const getNotApplicableMessage = (reason: string) =>
  `Not applicable for this portfolio (${reason})`

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '1rem' }} />}
    {...props}
  />
))(() => ({
  margin: 0,
  padding: 0,
  minHeight: 0,
  '& .MuiAccordionSummary-content': {
    flexDirection: 'row',
    display: 'flex',
    margin: 0,
  },
}))

export type PortfolioDataCellProps = {
  id: string
  showAbsoluteValues?: boolean
  isGraphLoading: boolean
  filterName: string
  sectionName: string
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>
  expandable: boolean
  expanded: boolean
  data: DatapointValue[]
  subportfolioMode?: boolean
  divideValueBy: number
}

const accordionStyle = {
  padding: 0,
  minHeight: 0,
  '&:before': {
    display: 'none',
  },
}
const transitionProps = { timeout: 150, unmountOnExit: true }
const tableCellStyle = { padding: 1 }
const accordionDetailsStyle = {
  padding: 0,
  paddingTop: 1,
}

const PortfolioTypeDataCell: React.FC<PortfolioDataCellProps> = ({
  id,
  filterName,
  expandable,
  expanded,
  isGraphLoading,
  setExpanded,
  showAbsoluteValues,
  data,
  divideValueBy,
}) => {
  const focus = useFocusAddress()
  const [notApplicableMessage, setNotApplicableMessage] = React.useState('')

  const asset = useSelector((state: RootState) => {
    return state.datapoints?.[id]?.[filterName]
  })

  const value =
    asset && asset.value !== null
      ? displayCellData(asset.value, divideValueBy)
      : undefined

  const focusAsset = useSelector(
    (state: RootState) =>
      state.datapoints?.[`${focus?.lat};${focus?.long}`]?.[filterName]?.value,
    equals
  )

  const attentType = useDifferenceType(
    focusAsset,
    asset?.value,
    showAbsoluteValues
  )

  const differenceText = React.useMemo(() => {
    const focusAssetScore = Number(focusAsset)
    const assetScore = Number(asset?.value)

    // don't show difference
    if (
      Math.abs(focusAssetScore - assetScore) < 1 ||
      !focusAssetScore ||
      !assetScore ||
      showAbsoluteValues
    ) {
      return undefined
    }
    if (focusAssetScore > assetScore) {
      return `-${displayCellData(
        Math.abs(focusAssetScore - assetScore),
        divideValueBy
      )}`
    }

    return `+${displayCellData(
      Math.abs(focusAssetScore - assetScore),
      divideValueBy
    )}`
  }, [asset, focusAsset, showAbsoluteValues, divideValueBy])

  const dataArray = React.useMemo(
    () =>
      data
        ? data
            .map(({ value: dataValue }) => dataValue)
            .filter((x) => x !== null)
        : [],
    [data]
  ) as number[]

  React.useEffect(() => {
    if (dataArray.length > 1 && dataArray.every((x) => x === null)) {
      return setNotApplicableMessage('Not available')
    }
    if (dataArray.length > 1 && uniq(dataArray).length === 1) {
      return setNotApplicableMessage(
        getNotApplicableMessage('each asset has the same value')
      )
    }
    return setNotApplicableMessage('')
  }, [dataArray])

  const changeExpanded = React.useCallback(
    (_, newExpanded) => setExpanded(newExpanded),
    [setExpanded]
  )

  return (
    <TableCell sx={tableCellStyle}>
      {expandable ? (
        <Accordion
          expanded={expanded}
          onChange={changeExpanded}
          TransitionProps={transitionProps}
          elevation={0}
          disableGutters
          sx={accordionStyle}
        >
          <CustomAccordionSummary>
            <Box sx={{ width: '100%' }}>
              <LoadingValue value={asset}>
                <ColorNumber
                  differenceText={differenceText}
                  value={String(value || '')}
                  variant={attentType}
                />
              </LoadingValue>
            </Box>
          </CustomAccordionSummary>
          <AccordionDetails sx={accordionDetailsStyle}>
            {data && (
              <Box height={TABLE_CELL_WIDTH} width={TABLE_CELL_WIDTH}>
                {notApplicableMessage ? (
                  <Typography variant='body2'>
                    {notApplicableMessage}
                  </Typography>
                ) : isGraphLoading ? (
                  <Skeleton sx={{ height: '100%' }} />
                ) : (
                  <NormalDist data={dataArray} assetPoint={focusAsset} />
                )}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Box display='flex'>
          <LoadingValue value={asset}>
            <ColorNumber
              differenceText={differenceText}
              value={String(value || '')}
              variant={attentType}
            />
          </LoadingValue>
        </Box>
      )}
    </TableCell>
  )
}

export default React.memo(PortfolioTypeDataCell)
