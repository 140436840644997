import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material'
import {
  Home as HomeIcon,
  Collections as CollectionsIcon,
  FileDownload,
  Clear,
} from '@mui/icons-material'
import AlertDialog from 'components/AlertDialog'
import { useExportData } from 'utils/network'
import { useDispatch } from 'react-redux'
import { reset } from 'reducers/assetView'
import * as React from 'react'

type AddSpeedDialProps = {
  openAddPortfolioDialog: () => void
  openAddAddressDialog: () => void
}

const speedDialStyle = { position: 'fixed', bottom: 16, right: 16 }

const AddSpeedDial: React.FC<AddSpeedDialProps> = ({
  openAddAddressDialog,
  openAddPortfolioDialog,
}) => {
  const dispatch = useDispatch()
  const exportData = useExportData()
  const resetAssetView = React.useCallback(() => dispatch(reset()), [dispatch])
  const [clearDialogOpen, setClearDialogOpen] = React.useState(false)
  const openClearDialog = React.useCallback(() => setClearDialogOpen(true), [])

  return (
    <>
      <AlertDialog
        open={clearDialogOpen}
        setOpen={setClearDialogOpen}
        title='Clear columns?'
        information='If you want to remove all the assets and portfolios click Agree.'
        onAgree={resetAssetView}
      />
      <SpeedDial
        ariaLabel='Add SpeedDial'
        sx={speedDialStyle}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          onClick={openClearDialog}
          icon={<Clear />}
          tooltipTitle='Clear columns'
        />
        <SpeedDialAction
          onClick={openAddPortfolioDialog}
          icon={<CollectionsIcon />}
          tooltipTitle='Add portfolio'
        />
        <SpeedDialAction
          onClick={openAddAddressDialog}
          icon={<HomeIcon />}
          tooltipTitle='Add address'
        />
        <SpeedDialAction
          onClick={exportData}
          icon={<FileDownload />}
          tooltipTitle='Export'
        />
      </SpeedDial>
    </>
  )
}

export default React.memo(AddSpeedDial)
