export const displayFloat = (x: number, digits: number = 1) =>
  parseFloat(x.toFixed(digits)).toString()

export const normalExpectation = (data: number[]) =>
  data.reduce((a, b) => a + b, 0) / data.length

export const normalStandardDeviation = (data: number[], expectation: number) =>
  Math.sqrt(
    data.reduce((a, b) => a + Math.pow(b - expectation, 2), 0) / data.length
  )

export const normalPdf = (mean: number, std: number, x: number) =>
  (1 / (std * Math.sqrt(2 * Math.PI))) *
  Math.exp((-1 / 2) * Math.pow((x - mean) / std, 2))

export const displayCellData = (
  value: number,
  divideValueBy: number
): string => {
  return (value / divideValueBy).toFixed(1).replace('.', ',')
}
