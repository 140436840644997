import React, { useCallback, useState, memo } from 'react'
import { equals } from 'ramda'
import { useGetUserQuery, useUpdatePasswordMutation } from 'services'
import Box from '@mui/system/Box'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  DialogContent,
  Button,
} from '@mui/material'
import useAlertEffect from 'hooks/useAlertEffect'

type EditUserDialogProps = {
  open: boolean
  handleClose: () => void
}

const EditUserDialog = ({ open, handleClose }: EditUserDialogProps) => {
  const [updatePassword, { isSuccess, error }] = useUpdatePasswordMutation()
  useAlertEffect({
    data: isSuccess,
    successMessage: 'Password updated',
    error,
    errorMessage:
      'Check if you entered your correct current password and try again.',
  })
  const { data: user } = useGetUserQuery()
  const [newPassword, setNewPassword] = useState('')
  const [repeatedNewPassword, setRepeatedNewPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')

  const confirmEditPassword = useCallback(() => {
    updatePassword({
      currentPassword,
      newPassword,
      userId: user?.id || '',
    })
      .unwrap()
      .then(() => {
        handleClose()
      })
  }, [updatePassword, user?.id, currentPassword, newPassword, handleClose])

  const onChangePassword = useCallback(
    (e) => setNewPassword(e.target.value),
    []
  )
  const onChangeRepeatedPassword = useCallback(
    (e) => setRepeatedNewPassword(e.target.value),
    []
  )
  const onChangeCurrentPassword = useCallback(
    (e) => setCurrentPassword(e.target.value),
    []
  )

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit password</DialogTitle>
      <DialogContent sx={{ marginY: 2 }}>
        <Box display='flex' justifyContent='space-between'>
          <TextField
            value={newPassword}
            sx={{ width: 300, marginBottom: 1, marginTop: 2, marginRight: 1 }}
            autoComplete='off'
            type='password'
            label='new password'
            onChange={onChangePassword}
          />
        </Box>
        <div>
          <TextField
            sx={{ width: 300, marginBottom: 1, marginRight: 1 }}
            value={repeatedNewPassword}
            autoComplete='off'
            type='password'
            label='repeat new password'
            onChange={onChangeRepeatedPassword}
            error={!equals(repeatedNewPassword, newPassword)}
            helperText={
              equals(repeatedNewPassword, newPassword)
                ? ''
                : "Passwords don't match"
            }
          />
        </div>
        <div>
          <TextField
            sx={{ width: 300, marginBottom: 1, marginRight: 1 }}
            type='password'
            autoComplete='off'
            label='current password'
            value={currentPassword}
            onChange={onChangeCurrentPassword}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          close
        </Button>
        <Button
          color='primary'
          onClick={confirmEditPassword}
          disabled={
            !currentPassword ||
            !newPassword ||
            !equals(repeatedNewPassword, newPassword)
          }
        >
          edit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default memo(EditUserDialog)
