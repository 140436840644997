import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { equals } from 'ramda'

const usePortfolios = () => {
  return useSelector(
    (state: RootState) => Object.values(state.assetView.portfolio),
    equals
  )
  // const [searchParams] = useSearchParams()

  // return React.useMemo(() => {
  //   const portfolios = searchParams.getAll('portfolio')

  //   return portfolios.map(decodePortfolio)
  // }, [searchParams])
}

export default usePortfolios
