import { api } from '../services'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import assetDataReducer from '../reducers/assetData'
import filterReducer from '../reducers/filter'
import clusterViewReducer from '../reducers/clusterView'
import datapointsReducer from '../reducers/datapoints'
import assetViewReducer from '../reducers/assetView'
import type { Action } from 'redux'
import type { ThunkAction } from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['assetView'],
}

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  filter: filterReducer,
  assetData: assetDataReducer,
  clusterView: clusterViewReducer,
  assetView: assetViewReducer,
  datapoints: datapointsReducer,
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
})

setupListeners(store.dispatch)

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

const persistor = persistStore(store)

export { store, persistor }
