import * as React from 'react'
import { sortBy } from 'lodash'
import { useGetFilterConfigQuery } from '../../services'
import { CONFIG_ORDER } from '../../constants'

const useSortedFilterConfigQuery = () => {
  const { data: config } = useGetFilterConfigQuery()

  const sortedConfig = React.useMemo(() => {
    return sortBy(config || [], ({ name }) =>
      CONFIG_ORDER.findIndex((value) => value === name)
    )
  }, [config])

  return sortedConfig
}

export default useSortedFilterConfigQuery
