import * as React from 'react'

const useCreateMapFromKey = (list: any[], key: string) =>
  React.useMemo(() => {
    const map: Record<string, unknown[]> = {}

    list.forEach((item) => {
      if (item[key]) {
        if (!map[item[key]]) {
          map[String(item[key])] = [item]
        } else {
          map[String(item[key])] = [...map[item[key]], item]
        }
      }
    })

    return map
  }, [list, key])

export default useCreateMapFromKey
