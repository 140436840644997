import * as React from 'react'
import { Snackbar, Alert } from '@mui/material'
import useOpenAndClose from 'hooks/useOpenAndClose'
import type { AlertProps } from '@mui/material'

export type AlertState = { severity: AlertProps['severity']; message: string }

const alertContext = React.createContext(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (newAlertState: AlertState) => {}
)

const AlertProvider: React.FC = ({ children }) => {
  const [alertState, setAlertState] = React.useState<AlertState>({
    message: '',
    severity: 'success',
  })
  const [isOpen, open, close] = useOpenAndClose()

  const setToast = React.useCallback(
    (newAlertState: AlertState) => {
      setAlertState(newAlertState)
      open()
    },
    [open]
  )

  return (
    <>
      <alertContext.Provider value={setToast}>{children}</alertContext.Provider>
      <Snackbar
        open={isOpen}
        onClose={close}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={close}
          severity={alertState.severity}
          sx={{ width: '100%' }}
          variant='filled'
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default AlertProvider

export const useToast = () => React.useContext(alertContext)
