import { API_BASE_URL, fetchJson } from 'utils/network'
import { AppThunk } from 'store'
import { addData } from 'reducers/datapoints'
import { getToken } from 'utils/auth'
import { DatapointValue } from 'typescript-types'
import { createErrorState } from 'thunks/createErrorState'
import { setMissingValuesToNull } from 'thunks/utils'

const fetchAssetData =
  (latlongs: string[], newFilters: string[]): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      if (newFilters.length === 0) {
        return true
      }

      const filterQuery = newFilters
        .map((filter) => {
          return `id=${encodeURIComponent(filter)}`
        })
        .join('&')

      const latlongQuery = latlongs
        .map((latlong) => {
          return `latlong=${encodeURIComponent(latlong)}`
        })
        .join('&')

      const tk = getToken()
      const url = `${API_BASE_URL}/data/datapoints/by-latlong?${latlongQuery}&${filterQuery}&tk=${tk}`
      const response = (await fetchJson(url)) as Record<
        string,
        Record<DatapointValue['id'], DatapointValue>
      >

      dispatch(addData(setMissingValuesToNull(latlongs, newFilters, response)))
      return true
    } catch (e) {
      console.error('e', e)
      const errorState = createErrorState(latlongs, newFilters)
      dispatch(addData(errorState))
      return false
    }
  }

export default fetchAssetData
