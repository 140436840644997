import * as React from 'react'
import { useGoogleMapsService } from 'contexts/GoogleMapsService'
import useFocusAddress from 'hooks/useFocusAddress'
import useLatlongs from 'hooks/useLatlongs'
import { RootState } from 'store'
import { useSelector } from 'react-redux'
import { useGetAssetsByPortfolioIdQuery } from 'services'
import { uniq } from 'ramda'

const Map = () => {
  const portfolios = useSelector(
    (state: RootState) => state.assetView.portfolio
  )
  const subportfolios = useSelector(
    (state: RootState) => state.assetView.subportfolio
  )
  const portfolioIds = useSelector((state: RootState) => {
    const tempPortfolios = state.assetView.portfolio
    const tempSubportfolios = state.assetView.subportfolio
    const tempPortfolioIds = Object.keys(tempPortfolios)
    const subportfolioPortfolioIds = Object.values(tempSubportfolios).map(
      ({ portfolioId }) => portfolioId
    )
    const allPortfolioIds = [...tempPortfolioIds, ...subportfolioPortfolioIds]

    const uniquePortfolioIds = uniq(allPortfolioIds)
    uniquePortfolioIds.sort()
    return uniquePortfolioIds
  })
  const { data: locations } = useGetAssetsByPortfolioIdQuery(portfolioIds)

  const addresses = useLatlongs()
  const [ref, setRef] = React.useState<HTMLDivElement>()
  const focus = useFocusAddress()
  const { initMap, addMarker } = useGoogleMapsService()

  const map = React.useMemo(() => {
    try {
      if (
        ref &&
        initMap &&
        addMarker &&
        focus?.lat !== undefined &&
        focus?.long !== undefined
      ) {
        const tempMap = initMap(ref, {
          lat: Number(focus?.lat),
          lng: Number(focus?.long),
        })
        addMarker(tempMap, 'green', {
          lat: Number(focus?.lat),
          lng: Number(focus?.long),
        })
        return tempMap
      }
    } catch (e) {
      console.error(e)
    }
  }, [ref, initMap, focus?.lat, focus?.long, addMarker])

  React.useEffect(() => {
    locations?.forEach(({ portfolioId, assets }) => {
      assets.forEach(({ latitude, longitude, subportfolioId }) => {
        if (subportfolios[subportfolioId]) {
          const subportfolio = subportfolios[subportfolioId]
          addMarker(map, subportfolio.color, { lat: latitude, lng: longitude })
        } else if (portfolios[portfolioId]) {
          const portfolio = portfolios[portfolioId]
          addMarker(map, portfolio.color, { lat: latitude, lng: longitude })
        }
      })
    })
  }, [portfolios, subportfolios, locations, addMarker, map])

  React.useEffect(() => {
    addresses.forEach(({ lat, long }) => {
      addMarker(map, 'red', { lat: Number(lat), lng: Number(long) })
    })
  }, [map, addresses, addMarker])

  const changeRef = React.useCallback(
    (element: HTMLDivElement) => setRef(element),
    []
  )

  return (
    <div
      ref={changeRef}
      style={{ height: 'calc(100vh - 114px)', width: '100%' }}
    />
  )
}

export default React.memo(Map)
