import * as React from 'react'
import { useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import useSyncSearchParams from '../../../hooks/useSyncSearchParams'
import type { RootState } from '../../../store'
import type { TLeafCheckbox } from './types'

const LeafCheckbox: React.FC<TLeafCheckbox> = ({ id }) => {
  const value = useSelector((state: RootState) => {
    return !!state.filter.filters?.[id]
  })

  const syncSearchParamsWithState = useSyncSearchParams()

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { searchParams } = new URL(document.location.href)
      if (e.target.checked) {
        searchParams.append(`filter`, id)

        window.history.replaceState(
          {},
          '',
          `${document.location.pathname}?${searchParams}`
        )
        syncSearchParamsWithState()
      } else {
        const newSearchParams = new URLSearchParams()
        searchParams.forEach((searchParamValue, key) => {
          if (!(key === `filter` && searchParamValue === id)) {
            newSearchParams.append(key, searchParamValue)
          }
        })
        window.history.replaceState(
          {},
          '',
          `${document.location.pathname}?${newSearchParams}`
        )
        syncSearchParamsWithState()
      }
    },
    [id, syncSearchParamsWithState]
  )

  const onClick = React.useCallback((e) => e.stopPropagation(), [])

  return (
    <Checkbox
      checked={value || false}
      onChange={onChange}
      onClick={onClick}
      size='small'
    />
  )
}

export default React.memo(LeafCheckbox)
