import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import fetchAssetData from 'thunks/fetchAssetData'
import fetchPortfolioData from 'thunks/fetchPortfolioData'
import fetchSubportfolioData from 'thunks/fetchSubportfolioData'
import { RootState, store } from 'store'
import useLatlongs from 'hooks/useLatlongs'
import useFocusAddress from 'hooks/useFocusAddress'
import usePortfolios from 'hooks/usePortfolios'
import useSubportfolios from 'hooks/useSubportfolios'
import { Subportfolio } from 'typescript-types'
import useSubclusterDatapointMap from 'hooks/useSubclusterDatapointMap'

const AssetDataService: React.FC = ({ children }) => {
  const subclusterDatapointMap = useSubclusterDatapointMap()

  const dispatch = useDispatch()
  const latlongs = useLatlongs()
  const portfolios = usePortfolios()
  const subportfolios = useSubportfolios()
  const focus = useFocusAddress()
  const latLongsWithFocus = React.useMemo(
    () => (focus ? [...latlongs, focus] : latlongs),
    [latlongs, focus]
  )
  const filters = useSelector((state: RootState) => state.filter.filters || {})
  const clusterDatapoint = useSelector(
    (state: RootState) => state.clusterView.state?.datapoints
  )

  const filtersWithClusterKeys = React.useMemo(() => {
    const clusterDatapointIds = clusterDatapoint?.map(({ id }) => id) || []
    const subclusterDatapointIds = Object.keys(filters).map((filter) => {
      const subcluster = subclusterDatapointMap[filter]
      if (!subcluster) {
        return undefined
      }
      return subcluster.subclusterDatapointId
    })
    return [...clusterDatapointIds, ...subclusterDatapointIds].filter(
      (x) => x
    ) as string[]
  }, [clusterDatapoint, filters, subclusterDatapointMap])

  React.useEffect(() => {
    const state = store.getState()
    const encodedLatlongs = latLongsWithFocus.map(
      ({ lat, long }) => `${lat};${long}`
    )
    const newFiltersSet = new Set<string>()
    const newLatlongsSet = new Set<string>()

    filtersWithClusterKeys.forEach((datapointId) => {
      encodedLatlongs.forEach((latlong) => {
        if (state.datapoints?.[latlong]?.[datapointId] === undefined) {
          newFiltersSet.add(datapointId)
          newLatlongsSet.add(latlong)
        }
      })
    })

    const newFilters = Array.from(newFiltersSet)
    const newLatlongs = Array.from(newLatlongsSet)

    dispatch(fetchAssetData(newLatlongs, newFilters))
  }, [
    filtersWithClusterKeys,
    clusterDatapoint,
    dispatch,
    latLongsWithFocus,
    subclusterDatapointMap,
  ])

  React.useEffect(() => {
    const state = store.getState()
    const newFiltersSet = new Set<string>()
    const newPortfoliosIdsSet = new Set<string>()

    filtersWithClusterKeys.forEach((datapointId) => {
      portfolios.forEach((portfolio) => {
        if (state.datapoints?.[portfolio.id]?.[datapointId] === undefined) {
          newFiltersSet.add(datapointId)
          newPortfoliosIdsSet.add(portfolio.id)
        }
      })
    })

    const newFilters = Array.from(newFiltersSet)
    const newPortfolioIds = Array.from(newPortfoliosIdsSet)

    newPortfolioIds.forEach((portfolioId) => {
      dispatch(fetchPortfolioData(portfolioId, newFilters))
    })
  }, [portfolios, focus, filtersWithClusterKeys, dispatch])

  React.useEffect(() => {
    const state = store.getState()
    const newFiltersSet = new Set<string>()
    const newSubportfoliosSet = new Set<Subportfolio>()

    filtersWithClusterKeys.forEach((datapointId) => {
      subportfolios.forEach((subportfolio) => {
        if (state.datapoints?.[subportfolio.id]?.[datapointId] === undefined) {
          newFiltersSet.add(datapointId)
          newSubportfoliosSet.add(subportfolio)
        }
      })
    })

    const newFilters = Array.from(newFiltersSet)
    const newSubportfolios = Array.from(newSubportfoliosSet)

    newSubportfolios.forEach((subportfolio) => {
      dispatch(fetchSubportfolioData(subportfolio, newFilters))
    })
  }, [subportfolios, focus, filtersWithClusterKeys, dispatch])

  return <>{children}</>
}

export default AssetDataService
