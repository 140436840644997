import { styled } from '@mui/material/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon fontSize='small' />}
    {...props}
  />
))(() => ({
  margin: 0,
  padding: 0,
  minHeight: 0,
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}))

export default CustomAccordionSummary
